// App.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// pages
import Home from "./pages/Home";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import EmployeeManagement from "./pages/management/EmployeeManagement";
import CustomerManagement from "./pages/management/CustomerManagement";
import SubcontractorManagement from "./pages/management/SubcontractorManagement";
import AuthorityManagement from "./pages/management/AuthorityManagement";
import InputCustomerInfo from "./pages/InputCustomerInfo";
import InputInfoConfirmation from "./pages/InputInfoConfirmation";
import EstimationDisplay from "./pages/EstimationDisplay";
import OrderAcceptanceCompletion from "./pages/OrderAcceptanceCompletion";
import TestPage from "./pages/TestPage";
import PrivateRoute from "./components/Auth/PrivateRoute";
import AdminRoute from "./components/Auth/AdminRoute";
import ResetPassword from "./pages/auth/ResetPassword";
import CustomerRegister from "./pages/auth/CustomerRegister";
import EstimateAndDeliveryPlan from "./pages/EstimateAndDeliveryPlan";
import EmployeeDashboard from "./pages/dashboard/EmployeeDashboard";
import CustomerDashboard from "./pages/dashboard/CustomerDashboard";
import TicketDetail from "./pages/TicketDetail";
import SubcontractorDashboard from "./pages/dashboard/SubcontractorDashboard";

// components
// import FileUpload from "./components/FileUpload/FileUpload";

const App: React.FC = () => {
  // const handleFileUpload = (file: File) => {
  //   // ファイルのアップロード処理を実装する
  //   console.log('Uploading file:', file);
  //   // ここでファイルをサーバーにアップロードする処理を追加
  // };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/register" element={<CustomerRegister />} />
        <Route
          path="/estimate-delivery-plan"
          element={<EstimateAndDeliveryPlan />}
        />
        <Route path="/input-customer-info" element={<InputCustomerInfo />} />
        <Route
          path="/input-info-confirmation"
          element={<InputInfoConfirmation />}
        />
        <Route element={<PrivateRoute />}>
          <Route element={<AdminRoute />}>
            <Route
              path="/management/employee"
              element={<EmployeeManagement />}
            />
            <Route
              path="/management/customer"
              element={<CustomerManagement />}
            />
            <Route
              path="/management/subcontractor"
              element={<SubcontractorManagement />}
            />
            <Route
              path="/management/authority"
              element={<AuthorityManagement />}
            />
            <Route path="/dashboard/employee" element={<EmployeeDashboard />} />
          </Route>
          <Route path="/estimation-display" element={<EstimationDisplay />} />
          <Route
            path="/order-acceptance-completion"
            element={<OrderAcceptanceCompletion />}
          />
          <Route
            path="/dashboard/subcontractor"
            element={<SubcontractorDashboard />}
          />
          <Route path="/dashboard/customer" element={<CustomerDashboard />} />
          <Route path="/ticket/:id" element={<TicketDetail />} />
        </Route>
        <Route path="/test-page" element={<TestPage />} />
        <Route path="/*" element={<Home />}></Route>
      </Routes>
    </Router>
    // <div>
    //   <h1>My App</h1>
    //   <FileUpload onUpload={handleFileUpload} />
    //   {/* 他のコンポーネントをここに追加する */}
    // </div>
  );
};

export default App;
