import "chart.js/auto";
import "chartjs-adapter-date-fns";
import { Bar } from "react-chartjs-2";
import { addDays, format } from "date-fns";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { DataOrder, Ticket } from "../../common/constants";
import { getformatDateFromISOFormat, toJSTISOString } from "../Utils/utils";
import axiosConfig from "../../axiosConfig";
import { useAppSelector } from "../../app/hooks";

const GanttChart: React.FC = () => {
  const accountInfo = useAppSelector((state) => state.accountInfo);
  const [chartData, setChartData] = useState<any[]>([]);
  const [dataGanttChart, setDataGanttChart] = useState<any[]>([]);
  const [showSearchContent, setShowSearchContent] = useState(true);
  const [rangeTime, setRangeTime] = useState({
    min: format(addDays(new Date(), -14), "yyyy-MM-dd"),
    max: format(addDays(new Date(), 14), "yyyy-MM-dd"),
  });

  const [loading, setLoading] = useState(false);
  const [customerNameList, setCustomerNameList] = useState<string[]>([]);
  const [projectNameList, setProjectNameList] = useState<string[]>([]);
  const [fileNameList, setFileNameList] = useState<string[]>([]);
  const searchQuery = useRef({
    customerName: "",
    projectName: "",
    fileName: "",
  });

  useEffect(() => {
    setLoading(true);
    axiosConfig({
      method: "get",
      url: `/development/api/dashboard/gantt-chart-data?from_date=${rangeTime.min}&to_date=${rangeTime.max}`,
    }).then((res) => {
      if (res.status === 200 && res.data != null) {
        setChartData(res.data.body);
        const customerNameList: string[] = [];
        res.data.body.forEach((item: any) => {
          if (!customerNameList.includes(item.order.customer_name)) {
            customerNameList.push(item.order.customer_name);
          }
        });
        const projectNameList: string[] = [];
        res.data.body.forEach((item: any) => {
          if (!projectNameList.includes(item.order.project_name)) {
            projectNameList.push(item.order.project_name);
          }
        });
        const fileNameList: string[] = [];
        res.data.body.forEach((item: any) => {
          item.tickets.forEach((ticket: any) => {
            if (!fileNameList.includes(ticket.file_name)) {
              fileNameList.push(ticket.file_name);
            }
          });
        });
        setDataGanttChart(formatDataToDataGanttChart(res.data.body));
        setCustomerNameList(customerNameList);
        setProjectNameList(projectNameList);
        setFileNameList(fileNameList);
        setLoading(false);
      }
    });
  }, []);

  const formatDataToDataGanttChart = (data: any[]) => {
    return data
      .map((dataOrder: DataOrder, index: number) => {
        if (dataOrder.tickets.length > 0) {
          const rootDataRange = chartData[index] ?? dataOrder;
          const minStartDate = new Date(
            Math.min(
              ...rootDataRange.tickets.map((item: Ticket) =>
                new Date(item.created_at).getTime()
              )
            )
          );
          const maxDeliveryDate = new Date(
            Math.max(
              ...rootDataRange.tickets.map((item: Ticket) =>
                new Date(item.delivery_at).getTime()
              )
            )
          );
          const tempDataArr = [
            {
              label: "",
              x: [new Date(minStartDate), new Date(maxDeliveryDate)],
              y: dataOrder.order.project_name + "*" + dataOrder.order.id,
              type: "order",
              status: "",
            },
          ];
          dataOrder.tickets.forEach((ticket: any) => {
            tempDataArr.push({
              label: ticket.id,
              x: [new Date(ticket.created_at), new Date(ticket.delivery_at)],
              y: ticket.file_name + "*" + ticket.id,
              type: "ticket",
              status: ticket.status,
            });
          });
          return tempDataArr;
        }
        return [];
      })
      .flat();
  };
  const data = {
    datasets: [
      {
        data: [...dataGanttChart],
        borderSkipped: false,
        barPercentage: 1,
        categoryPercentage: 1,
        barThickness: 200,
      },
    ],
  };

  let maxHeightChart = dataGanttChart.length * 6 + 37;

  //-----Plugins-start-----
  const plugins: any = [
    // {
    //   id: "todayLine",
    //   afterDatasetsDraw(chart: {
    //     ctx: any;
    //     data: any;
    //     chartArea: { top: any; bottom: any; left: any; right: any };
    //     scales: { x: any; y: any };
    //   }) {
    //     const {
    //       ctx,
    //       chartArea: { top, bottom },
    //       scales: { x },
    //     } = chart;
    //     ctx.save();
    //     ctx.beginPath();
    //     ctx.lineWidth = 3;
    //     ctx.strokeStyle = "rgba(0, 0, 0, 1)";
    //     ctx.setLineDash([6, 6]);
    //     ctx.moveTo(x.getPixelForValue(new Date()), top);
    //     ctx.lineTo(x.getPixelForValue(new Date()), bottom);
    //     ctx.stroke();
    //     ctx.setLineDash([]);
    //   },
    // },
    {
      id: "customBarPlugin",
      beforeDatasetDraw(chart: any) {
        const { ctx, scales } = chart;
        const dataset = chart.data.datasets[0];
        const meta = chart.getDatasetMeta(0);
        ctx.save();
        dataset.data.forEach((dataPoint: any, index: number) => {
          const bar = meta.data[index];
          const xStart = scales.x.getPixelForValue(new Date(dataPoint.x[0]));
          const xEnd = Math.min(
            scales.x.getPixelForValue(new Date(dataPoint.x[1])),
            scales.x.getPixelForValue(chart.scales.x.max)
          );

          const barWidth =
            dataPoint.type === "order"
              ? (scales.y._length / chart.data.labels.length) * 0.3
              : scales.y._length / chart.data.labels.length;

          const yStart = bar.y - barWidth / 2;
          const barHeight = barWidth;

          if (xStart !== undefined && xEnd !== undefined && xStart > 0) {
            let barColor = dataset.backgroundColor[index];
            if (dataPoint.status === "") {
              barColor = "#ff7f0e";
            }
            if (dataPoint.status === "create") {
              barColor = "#7f7f7f";
            }
            if (dataPoint.status === "download") {
              barColor = "#bcbd22";
            }
            if (dataPoint.status === "order") {
              barColor = "#17becf";
            }
            if (dataPoint.status === "contract") {
              barColor = "#8c564b";
            }
            if (dataPoint.status === "progress") {
              barColor = "#1f77b4";
            }
            if (dataPoint.status === "inspection") {
              barColor = "#9467bd";
            }
            if (dataPoint.status === "delivery") {
              barColor = "#2ca02c";
            }

            ctx.lineWidth = 1;
            ctx.strokeRect(xStart, yStart, xEnd - xStart, barHeight);
            ctx.fillStyle = barColor;
            ctx.fillRect(xStart, yStart, xEnd - xStart, barHeight);
          }
        });

        ctx.restore();
        return false;
      },
    },
    {
      id: "customLabelsDate",
      beforeDraw: (chart: any) => {
        const ctx = chart.ctx;
        const xScale = chart.scales.x;
        const yScale = chart.scales.y;

        const ticks = xScale.ticks;
        const groupedMonths: Record<
          string,
          Record<string, number[]>
        > = options.groupedMonths || {};
        const groupedYears: Record<string, number[]> =
          options.groupedYears || {};

        ticks.forEach((tick: any) => {
          const date = new Date(tick.value);
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const x = xScale.getPixelForValue(tick.value);

          // Group months
          if (!groupedMonths[year]) groupedMonths[year] = {};
          if (!groupedMonths[year][month]) groupedMonths[year][month] = [];
          groupedMonths[year][month].push(x);

          // Group years
          if (!groupedYears[year]) groupedYears[year] = [];
          groupedYears[year].push(x);
        });

        ctx.save();

        // Draw month labels
        ctx.font = "12px Arial";
        ctx.textAlign = "center";
        ctx.fillStyle = "#000";
        Object.entries(groupedMonths).forEach(([year, months]) => {
          Object.entries(months).forEach(([month, positions]) => {
            const xStart = positions[0];
            const xEnd = positions[positions.length - 1];
            const xCenter = (xStart + xEnd) / 2;
            const text = `${month}月`;
            const positionMonthMinus = 40;

            ctx.textAlign = "center";
            ctx.font = "18px Arial";
            ctx.fillStyle = "#333";
            ctx.fillText(text, xCenter, yScale.top - positionMonthMinus);

            const textHeight = 18;
            const padding = 10;
            const rectY =
              yScale.top - positionMonthMinus - textHeight / 2 - padding / 2;
            const rectWidth = xEnd - xStart;
            const rectHeight = textHeight + padding;

            ctx.fillStyle = "#eae0d5";
            ctx.fillRect(xStart, rectY, rectWidth, rectHeight);
            ctx.fillStyle = "#000";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(text, xCenter, yScale.top - positionMonthMinus);
          });
        });

        // Draw year labels
        Object.entries(groupedYears).forEach(([year, positions]) => {
          const xStart = positions[0];
          const xEnd = positions[positions.length - 1];
          const xCenter = (xStart + xEnd) / 2;
          const text = `${year}年`;
          const positionYesrMinus = 70;

          ctx.fillStyle = "#000";
          ctx.font = "21px Arial";
          ctx.textAlign = "center";
          ctx.fillText(text, xCenter, yScale.top - positionYesrMinus);

          const textHeight = 18;
          const padding = 10;
          const rectY =
            yScale.top - positionYesrMinus - textHeight / 2 - padding / 2;
          const rectWidth = xEnd - xStart;
          const rectHeight = textHeight + padding;

          ctx.fillStyle = "#adb5bd";
          ctx.fillRect(xStart, rectY, rectWidth, rectHeight);
          ctx.fillStyle = "#000";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillText(text, xCenter, yScale.top - positionYesrMinus);
        });

        ctx.restore();
      },
    },
    {
      id: "customBorderLabelBar",
      afterDraw(chart: any) {
        const { ctx, scales } = chart;
        const yScale = scales.y;
        const meta = chart.getDatasetMeta(0);

        ctx.save();
        yScale.ticks.forEach((tick: any, index: any) => {
          const bar = meta.data[index];
          const rectHeight = scales.y._length / chart.data.labels.length;
          const xStart = 10;
          const yStart = bar.y - rectHeight / 2;
          const rectWidth = scales.x.getPixelForValue(chart.scales.x.min);

          ctx.strokeStyle = "#666666";
          ctx.lineWidth = 0.25;

          ctx.beginPath();

          ctx.moveTo(xStart, yStart);
          ctx.lineTo(xStart, yStart + rectHeight);

          ctx.moveTo(xStart, yStart);
          ctx.lineTo(xStart + rectWidth - xStart, yStart);

          if (index === yScale.ticks.length - 1) {
            ctx.moveTo(xStart, yStart + rectHeight);
            ctx.lineTo(xStart + rectWidth - xStart, yStart + rectHeight);
          }
          ctx.stroke();
        });
        ctx.restore();
      },
    },
    {
      id: "WeekendBackground",
      beforeDraw(chart: any) {
        const { ctx, chartArea, scales } = chart;
        ctx.save();

        scales.x.ticks.forEach((tick: any, index: number) => {
          const day = new Date(tick.value).getDay();
          if (day === 6 || day === 0) {
            ctx.fillStyle = day === 6 ? "#DAE8FC" : "#F8CECC";
            ctx.fillRect(
              scales.x.getPixelForValue(tick.value),
              chartArea.top,
              scales.x.getPixelForValue(new Date(tick.value).setHours(24)) -
                scales.x.getPixelForValue(tick.value),
              chartArea.height
            );
          }
        });
      },
    },
  ];
  //-----Plugins-end-----

  // config
  const options: any = {
    layout: {
      padding: {
        top: 70,
        bottom: 10,
        left: 20,
        right: 10,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    indexAxis: "y",
    scales: {
      x: {
        position: "top",
        type: "time",
        time: {
          unit: "day",
        },
        min: rangeTime.min,
        max: rangeTime.max,
        offset: false,
        ticks: {
          align: "center",
          callback: function (value: any, index: any, ticks: any) {
            const date = new Date(ticks[index].value);
            const day = date.getDate();
            return `${day}`;
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 14,
          },
          crossAlign: "start",
          callback: function (value: any) {
            const newLabel =
              dataGanttChart[value].y.split("*")[0].length > 15
                ? `${dataGanttChart[value].y.split("*")[0].slice(0, 15)}...`
                : dataGanttChart[value].y.split("*")[0];
            if (dataGanttChart[value].type === "ticket") return `┗ ${newLabel}`;
            return `${newLabel}`;
          },
        },
      },
    },
  };

  const handleSearch = async () => {
    setLoading(true);
    let searchData: any[] = chartData.filter((item: any) => {
      let isValid = true;
      if (
        searchQuery.current.customerName !== "" &&
        item.order.customer_name !== searchQuery.current.customerName
      ) {
        isValid = false;
      }
      if (
        searchQuery.current.projectName !== "" &&
        item.order.project_name !== searchQuery.current.projectName
      ) {
        isValid = false;
      }
      if (isValid) return item;
    });
    if (searchQuery.current.fileName !== "") {
      const tempData = [...searchData];
      searchData = [];
      tempData.forEach((item: any) => {
        const ticketData = item.tickets.filter(
          (ticket: any) => ticket.file_name === searchQuery.current.fileName
        );
        if (ticketData.length > 0)
          searchData.push({
            order: item.order,
            tickets: ticketData,
          });
      });
    }
    setDataGanttChart(formatDataToDataGanttChart(searchData));
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  return (
    <>
      <Card
        className="mx-auto w-full"
        sx={{ border: 1, borderColor: "grey.300" }}
      >
        <CardHeader
          sx={{ bgcolor: "#F5F5F5", paddingY: "5px" }}
          subheader={
            <Button
              variant="text"
              sx={{ padding: 0, color: "black" }}
              disableRipple={true}
              onClick={() => setShowSearchContent(!showSearchContent)}
            >
              絞り込み
            </Button>
          }
        />
        {showSearchContent && (
          <CardContent sx={{ paddingY: "5px" }}>
            <Grid container spacing={2}>
              {accountInfo.type === "employee" && (
                <Grid item xs={2}>
                  <h3>顧客コード</h3>
                  <Autocomplete
                    freeSolo
                    className="mt-1"
                    size="small"
                    disableClearable
                    renderInput={(params) => <TextField {...params} />}
                    options={customerNameList}
                    value={searchQuery.current.customerName}
                    onInputChange={(event, value) =>
                      (searchQuery.current.customerName = value)
                    }
                  />
                </Grid>
              )}
              {accountInfo.type === "subcontractor" && (
                <>
                  <Grid item xs={2}>
                    <h3>案件名</h3>
                    <Autocomplete
                      freeSolo
                      className="mt-1"
                      size="small"
                      disableClearable
                      renderInput={(params) => <TextField {...params} />}
                      options={projectNameList}
                      value={searchQuery.current.projectName}
                      onInputChange={(event, value) =>
                        (searchQuery.current.projectName = value)
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <h3>ファイル名</h3>
                    <Autocomplete
                      freeSolo
                      className="mt-1"
                      size="small"
                      disableClearable
                      renderInput={(params) => <TextField {...params} />}
                      options={fileNameList}
                      value={searchQuery.current.fileName}
                      onInputChange={(event, value) =>
                        (searchQuery.current.fileName = value)
                      }
                    />
                  </Grid>
                </>
              )}
              {/* <Grid item xs={2}>
                <h3>作成日</h3>
                <Autocomplete
                  freeSolo
                  className="mt-1"
                  size="small"
                  disableClearable
                  renderInput={(params) => <TextField {...params} />}
                  options={usernameList}
                />
              </Grid>
              <Grid item xs={2}>
                <h3>配送日</h3>
                <Autocomplete
                  freeSolo
                  className="mt-1"
                  size="small"
                  disableClearable
                  renderInput={(params) => <TextField {...params} />}
                  options={usernameList}
                />
              </Grid> */}
            </Grid>
            <Stack className="mt-1">
              <div className="ml-auto">
                <Button
                  variant="contained"
                  sx={{ paddingX: "30px" }}
                  onClick={handleSearch}
                >
                  検索
                </Button>
              </div>
            </Stack>
          </CardContent>
        )}
      </Card>
      <Card
        className="mx-auto w-full mt-4"
        sx={{ border: 1, borderColor: "grey.300" }}
      >
        <Box className="py-3 flex">
          <Box
            className="flex mx-3"
            sx={{ alignItems: "center", typography: "body2" }}
          >
            <Box
              sx={{
                width: 40,
                height: 10,
                borderRadius: 1,
                bgcolor: "#7f7f7f",
                marginRight: 2,
              }}
            ></Box>
            <p>見積作成</p>
          </Box>
          <Box
            className="flex mx-3"
            sx={{ alignItems: "center", typography: "body2" }}
          >
            <Box
              sx={{
                width: 40,
                height: 10,
                borderRadius: 1,
                bgcolor: "#bcbd22",
                marginRight: 2,
              }}
            ></Box>
            <p>見積ダウンロード</p>
          </Box>
          <Box
            className="flex mx-3"
            sx={{ alignItems: "center", typography: "body2" }}
          >
            <Box
              sx={{
                width: 40,
                height: 10,
                borderRadius: 1,
                bgcolor: "#17becf",
                marginRight: 2,
              }}
            ></Box>
            <p>発注</p>
          </Box>
          <Box
            className="flex mx-3"
            sx={{ alignItems: "center", typography: "body2" }}
          >
            <Box
              sx={{
                width: 40,
                height: 10,
                borderRadius: 1,
                bgcolor: "#8c564b",
                marginRight: 2,
              }}
            ></Box>
            <p>受注</p>
          </Box>
          <Box
            className="flex mx-3"
            sx={{ alignItems: "center", typography: "body2" }}
          >
            <Box
              sx={{
                width: 40,
                height: 10,
                borderRadius: 1,
                bgcolor: "#1f77b4",
                marginRight: 2,
              }}
            ></Box>
            <p>作業中</p>
          </Box>
          <Box
            className="flex mx-3"
            sx={{ alignItems: "center", typography: "body2" }}
          >
            <Box
              sx={{
                width: 40,
                height: 10,
                borderRadius: 1,
                bgcolor: "#9467bd",
                marginRight: 2,
              }}
            ></Box>
            <p>検品中</p>
          </Box>
          <Box
            className="flex mx-3"
            sx={{ alignItems: "center", typography: "body2" }}
          >
            <Box
              sx={{
                width: 40,
                height: 10,
                borderRadius: 1,
                bgcolor: "#2ca02c",
                marginRight: 2,
              }}
            ></Box>
            <p>納品済み</p>
          </Box>
        </Box>
        {!loading && chartData.length > 0 && (
          <Bar
            options={options}
            data={data}
            plugins={plugins}
            height={maxHeightChart}
            width={"auto"}
          />
        )}
        {!loading && chartData.length === 0 && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              paddingY: 10,
            }}
          >
            <p style={{ marginLeft: "auto", marginRight: "auto" }}>
              表示するデータがありません。
            </p>
          </Box>
        )}
        {loading && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              paddingY: 10,
            }}
          >
            <CircularProgress
              sx={{ marginLeft: "auto", marginRight: "auto" }}
            />
          </Box>
        )}
      </Card>
    </>
  );
};
export default GanttChart;
