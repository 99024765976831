import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

interface PropsType {
  isSuccess: boolean;
  content: string;
}

const DialogSuccessBackLogin = (props: PropsType) => {
  const navigate = useNavigate();
  return (
    <Dialog
      open={props.isSuccess}
      aria-labelledby="responsive-dialog-title"
      maxWidth={"xs"}
    >
      <DialogContent>
        <Typography variant="body2" color="green">
          {props.content}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          style={{ marginLeft: "auto", marginRight: "auto" }}
          onClick={() => navigate("/login")}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSuccessBackLogin;
