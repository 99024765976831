import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface AccountInfoState {
  username: string;
  fullname: string;
  type: string;
  roles: {
    admin: boolean;
    checker: boolean;
  };
}

const initialState: AccountInfoState = {
  username: "",
  fullname: "",
  type: "",
  roles: {
    admin: false,
    checker: false,
  },
};

export const accountInfoSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setAccount: (state, action: PayloadAction<Partial<AccountInfoState>>) => {
      state.username = action.payload.username ?? "";
      state.fullname = action.payload.fullname ?? "";
      state.type = action.payload.type ?? "";
      state.roles.admin = action.payload.roles?.admin ?? false;
      state.roles.checker = action.payload.roles?.checker ?? false;
    },
    clearAccount: () => {
      return { ...initialState };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAccount, clearAccount } = accountInfoSlice.actions;

export const getAccountInfo = (state: AccountInfoState) => state;

export default accountInfoSlice.reducer;
