export type Order = {
  id: string;
  estimation_number: string;
  project_name: string;
  customer_name: string;
  pic: string;
  pic_ruby: string;
  phone_number: string;
  created_at: string;
};

export type Ticket = {
  id: string;
  progress: number;
  subcontractor_id: string;
  subcontractor_name: string;
  status:
    | "create"
    | "progress"
    | "download"
    | "order"
    | "contract"
    | "inspection"
    | "delivery"
    | "";
  created_at: string;
  delivery_at: string;
  file_name: string;
};

export type DataOrder = {
  order: Order;
  tickets: Ticket[];
};

export type FormData = {
  username: string;
  email: string;
  password: string;
  fullname: string;
  phone_number: string;
  confirmPassword: string;
  type: string;
  [key: string]: string | "";
};

export type ResDataOrder = {
  message: string;
  body: PaginationResponse<DataOrder>;
};

export enum DrawingStatus {
  create = "見積作成",
  download = "見積ダウンロード",
  order = "発注",
  contract = "受注",
  progress = "作業中",
  inspection = "検品中",
  delivery = "納品済み",
}

export type PaginationData = {
  page: number;
  per_page: number;
  page_count: number;
  total_count: number;
};

export type PaginationResponse<T> = {
  data: T[];
  metadata: PaginationData;
};
