import React, { useState, ChangeEvent } from "react";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid,
  Box,
  ListSubheader,
  Checkbox,
  styled,
} from "@mui/material";

// redux
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { estimationAmountState } from "../../features/estimationAmount/estimationAmountSlice";
import {
  removeEstimationFromList,
  toggleCheckBox,
  toggleCheckBoxAll,
  selectEstimationAmountList,
} from "../../features/estimationAmountList/estimationAmountListSlice";
import { getformatDateFromISOFormat } from "../Utils/utils";

interface estimationListProps {
  listItems: estimationAmountState[];
  isDisplay: boolean;
}

const EstimationList: React.FC<estimationListProps> = ({
  listItems,
  isDisplay,
}) => {
  // redux
  const dispatch = useAppDispatch();
  const estimation = useAppSelector(selectEstimationAmountList);
  // チェックボックスの色を変更するためのスタイルを定義
  const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    // チェック前の色を指定
    "&.MuiCheckbox-root": {
      color: "white",
    },
    // // チェック後の色を指定
    // "&.Mui-checked + .MuiFormControlLabel-label": {
    //   color: theme.palette.primary.main,
    // },
  }));

  interface CheckboxState {
    isChecked: boolean;
  }
  const [checkboxState, setCheckboxState] = useState<CheckboxState>({
    isChecked: false,
  });
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    // Checkboxの状態を更新
    setCheckboxState({
      isChecked: event.target.checked,
    });
    // Checkboxの状態に応じて処理を分岐
    if (event.target.checked) {
      // Checkboxがtrueの場合、すべてのチェックボックスを選択状態にする
      dispatch(toggleCheckBoxAll(event.target.checked));
    } else {
      // Checkboxがfalseの場合、すべてのチェックボックスを非選択状態にする
      dispatch(toggleCheckBoxAll(event.target.checked));
    }
  };

  // 見積削除処理
  const deleteEstimation = (itemIndex: number) => {
    dispatch(removeEstimationFromList(itemIndex));
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "baseline",
          m: "24px 0px",
          pl: 2,
          position: "relative",
          pb: 1, // paddingBottom: 16px
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "4px",
            backgroundImage:
              "linear-gradient(to right, #000000 30%, #8E8E8E 30%)",
          },
        }}
      >
        <Typography variant="h5">見積リスト</Typography>
        <Typography sx={{ paddingLeft: "20px" }}>
          見積書発行、発注はリストから選択してください。
        </Typography>
      </Box>
      <List sx={{ padding: "0px" }}>
        <ListSubheader
          sx={{
            backgroundColor: "#000000",
            color: "#ffffff",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={1} style={{ textAlign: "center", padding: "0px" }}>
              <StyledCheckbox
                checked={checkboxState.isChecked}
                onChange={handleCheckboxChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                padding: "0px",
              }}
            >
              <ListItemText
                primary="日時"
                sx={{
                  margin: "0px",
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                padding: "0px",
              }}
            >
              <ListItemText
                sx={{
                  margin: "0px",
                }}
                primary="金額"
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                padding: "0px",
              }}
            >
              <ListItemText
                sx={{
                  margin: "0px",
                }}
                primary="ファイル名"
              />
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                padding: "0px",
              }}
            ></Grid>
          </Grid>
        </ListSubheader>
      </List>
      <List sx={{ padding: "0px", marginTop: "0px" }}>
        {listItems.map((item, index) => {
          return (
            <ListItem
              key={index}
              sx={{
                padding: "0px 16px",
                backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white", // 背景色を設定
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container spacing={2} sx={{ marginTop: "0px" }}>
                <Grid
                  item
                  xs={1}
                  style={{
                    textAlign: "center",
                    padding: "0px",
                  }}
                >
                  <Checkbox
                    // クリックされたときにreduxへチェック状態を送信
                    onClick={() => {
                      dispatch(toggleCheckBox(index));
                      setCheckboxState({
                        isChecked: false,
                      });
                    }}
                    // reduxからチェック状態を参照する
                    checked={estimation[index].checkBox}
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    padding: "0px",
                  }}
                >
                  <ListItemText
                    // sx={{
                    //   margin: "0px",
                    //   padding: "0px",
                    // }}
                    primary={getformatDateFromISOFormat(
                      item.estimationDateTime
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    padding: "0px",
                  }}
                >
                  <ListItemText
                    // sx={{
                    //   margin: "0px",
                    //   padding: "0px",
                    // }}
                    primary={item.estimationAmount}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    padding: "0px",
                  }}
                >
                  <ListItemText
                    // sx={{
                    //   margin: "0px",
                    //   padding: "0px",
                    // }}
                    primary={item.estimationFileName}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    padding: "0px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      bgcolor: "grey.500", // グレーの色合いを調整
                      "&:hover": {
                        bgcolor: "grey.700", // ホバー時の色を調整
                      },
                    }}
                    onClick={() => deleteEstimation(index)}
                  >
                    削除
                  </Button>
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default EstimationList;
