import {
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Skeleton,
  Button,
} from "@mui/material";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import TicketDetails from "../interfaces/ticket";
import { DrawingStatus } from "../common/constants";
import axiosConfig from "../axiosConfig";
import { getformatDateFromISOFormat } from "../components/Utils/utils";
import { HttpError } from "../interfaces/error";
import ErrorComponent from "../components/Error/ErrorComponent";

const TicketDetail: React.FC<{
  ticketId?: string;
  onBackButtonClick?: () => void;
}> = ({ ticketId, onBackButtonClick }) => {
  const [ticket, setTicket] = React.useState<TicketDetails | null>(null);
  const [error, setError] = React.useState<HttpError | null>(null);
  const { id } = useParams();

  const fetchData = async () => {
    try {
      const response = await axiosConfig.get(
        `/development/api/dashboard/tickets/${ticketId ?? id}`
      );
      let data = response.data.body;
      data.status = DrawingStatus[data.status as keyof typeof DrawingStatus];
      setTicket(data);
      setError(null);
    } catch (error: any) {
      setError({
        statusCode: error.response.status,
        message: error.response.data.detail,
      } as HttpError);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  if (error) {
    return (
      <div className="flex flex-col">
        {id && <Header pageTitle="チケット詳細" />}
        <ErrorComponent statusCode={error.statusCode} message={error.message} />
        {id && <Footer />}
      </div>
    );
  }

  if (!ticket) {
    return (
      <div className="flex flex-col" style={{ marginBottom: "200px" }}>
        {id && <Header pageTitle="チケット詳細" />}
        <div
          style={{
            padding: "20px",
            width: ticketId ? "80vw" : "90vw",
            margin: "auto",
          }}
        >
          <Skeleton variant="rectangular" width="100%" height={50} />
          <Skeleton
            variant="text"
            width="60%"
            height={50}
            style={{ marginTop: "20px" }}
          />
          <Skeleton variant="text" width="40%" height={30} />
          <Skeleton variant="text" width="80%" height={30} />
          <Skeleton variant="text" width="90%" height={30} />
          <Skeleton variant="text" width="50%" height={30} />
          <Skeleton
            variant="text"
            width="60%"
            height={50}
            style={{ marginTop: "20px" }}
          />
          <Skeleton variant="text" width="60%" height={30} />
          <Skeleton variant="text" width="80%" height={30} />
          <Skeleton variant="text" width="90%" height={30} />
          <Skeleton variant="text" width="50%" height={30} />
          <Skeleton
            variant="text"
            width="60%"
            height={50}
            style={{ marginTop: "20px" }}
          />
          <Skeleton variant="text" width="60%" height={30} />
          <Skeleton variant="text" width="80%" height={30} />
        </div>
        {id && <Footer />}
      </div>
    );
  }

  return (
    <div className="flex flex-col" style={{ marginBottom: "200px" }}>
      {id && <Header pageTitle="チケット詳細" />}
      <div className="mx-auto" style={{ width: ticketId ? "80vw" : "90vw" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (id) {
              navigate(-1);
            } else {
              onBackButtonClick?.();
            }
          }}
          sx={{ marginTop: "20px", marginBottom: "10px" }}
        >
          戻る
        </Button>
      </div>
      <Stepper
        activeStep={Object.values(DrawingStatus).indexOf(
          ticket?.status ?? DrawingStatus.create
        )}
        alternativeLabel
        sx={{
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        {Object.values(DrawingStatus).map((status, index) => {
          return (
            <Step
              key={status}
              sx={{
                "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                  {
                    color: "primary.main",
                  },
                "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                  {
                    color: "primary.main",
                  },
              }}
            >
              <StepLabel>{status}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <InfoCard title="図面情報" width={ticketId ? "80vw" : "90vw"}>
        <Typography>
          <strong>チケットID:</strong> {ticket.ticket_id}
        </Typography>
        <Typography>
          <strong>図面ID:</strong> {ticket.drawing_id}
        </Typography>
        <Typography>
          <strong>外注先ID:</strong> {ticket.subcontractor_id}
        </Typography>
        <Typography>
          <strong>外注先名:</strong> {ticket.subcontractor_name}
        </Typography>
        <Typography>
          <strong>内容:</strong> {ticket.file_name}
        </Typography>
        <Typography>
          <strong>金額:</strong> {ticket.amount}
        </Typography>
        <Typography>
          <strong>状態:</strong> {ticket.status}
        </Typography>
        <Typography>
          <strong>進捗:</strong> {ticket.progress}
        </Typography>
        <Typography>
          <strong>納期:</strong>{" "}
          {getformatDateFromISOFormat(ticket.delivery_at)}
        </Typography>
      </InfoCard>

      <InfoCard title="注文情報" width={ticketId ? "80vw" : "90vw"}>
        <Typography>
          <strong>発注ID:</strong> {ticket.order_id}
        </Typography>
        <Typography>
          <strong>案件名:</strong> {ticket.project_name}
        </Typography>
        <Typography>
          <strong>見積番号:</strong> {ticket.estimation_number}
        </Typography>
        <Typography>
          <strong>作成日:</strong>{" "}
          {getformatDateFromISOFormat(ticket.created_at)}
        </Typography>
        <Typography>
          <strong>会社名:</strong> {ticket.company_name}
        </Typography>
        <Typography>
          <strong>住所:</strong> {ticket.address}
        </Typography>
        <Typography>
          <strong>住所2:</strong> {ticket.address2}
        </Typography>
        <Typography>
          <strong>郵便番号:</strong> {ticket.postal_code}
        </Typography>
        <Typography>
          <strong>Email:</strong> {ticket.email}
        </Typography>
        <Typography>
          <strong>電話番号:</strong> {ticket.phone_number}
        </Typography>
        <Typography>
          <strong>担当者のお名前:</strong> {ticket.pic}
        </Typography>
        <Typography>
          <strong>小計:</strong> {ticket.sub_total}
        </Typography>
        <Typography>
          <strong>消費税:</strong> {ticket.consumption_tax}
        </Typography>
        <Typography>
          <strong>合計:</strong> {ticket.total_amount}
        </Typography>
      </InfoCard>

      <InfoCard title="顧客情報" width={ticketId ? "80vw" : "90vw"}>
        <Typography>
          <strong>顧客ID:</strong> {ticket.customer_id}
        </Typography>
        <Typography>
          <strong>顧客名:</strong> {ticket.customer_name}
        </Typography>
      </InfoCard>

      {id && <Footer />}
    </div>
  );
};

const InfoCard: React.FC<{
  title: string;
  children: React.ReactNode;
  width?: string;
}> = ({ title, children, width }) => {
  return (
    <Card
      className="mx-auto mt-4"
      sx={{
        border: 1,
        borderColor: "grey.300",
        borderRadius: "10px",
        width: width ?? "90vw",
      }}
    >
      <CardContent
        sx={{
          "& > *": {
            marginBottom: 1,
          },
        }}
      >
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: "bold",
            marginBottom: 1,
          }}
        >
          {title}
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
};

export default TicketDetail;
