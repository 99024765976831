// Header.tsx
import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  ThemeProvider,
  Button,
  Fade,
  Menu,
  MenuItem,
} from "@mui/material";
import { theme } from "../Color/color";
import logo from "../../images/rakuCADtrace_logo.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LoginIcon from "@mui/icons-material/Login";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import Cookies from "js-cookie";
import { clearAccount } from "../../features/accountInfo/accountInfoSlice";
import { reset } from "../../features/customerInfo/customerInfoSlice";

interface HeaderProps {
  pageTitle?: string;
  hideTop?: Boolean;
}

const Header: React.FC<HeaderProps> = ({ pageTitle, hideTop = false }) => {
  // ナビゲーション
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const accountInfo = useAppSelector((state) => state.accountInfo);
  const dispatch = useAppDispatch();
  const token = Cookies.get("access_token");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigateManagement = (path: string) => {
    navigate(`/management/${path}`);
  };
  const handleNavigateDashboard = () => {
    navigate(`/dashboard/${accountInfo.type}`);
  };

  const handleLogout = async () => {
    dispatch(reset());
    dispatch(clearAccount());
    Cookies.remove("access_token");
    navigate("/login");
  };

  return (
    <AppBar position="static" sx={{ zIndex: 1300 }}>
      <Toolbar
        sx={{ backgroundColor: "#ffffff", borderBo: "1px solid #CCCCCC" }}
      >
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Box sx={{ flex: "0 1 16%" }}>
            <img
              src={logo}
              alt="raku cad trace"
              style={{ height: 60, marginLeft: 0, marginBottom: 4 }}
            />
          </Box>
          <Box sx={{ flex: "0 1 44%" }}>
            {pageTitle && (
              <Typography
                variant="h5"
                color="black"
                sx={{ paddingLeft: "20px" }}
              >
                {pageTitle}
              </Typography>
            )}
          </Box>
          <Box sx={{ flex: "0 1 40%", textAlign: "right" }}>
            {!hideTop && (
              <ThemeProvider theme={theme}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  TOPへ戻る
                </Button>
                {token ? (
                  <Button
                    id="fade-button"
                    aria-controls={open ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    style={{ marginLeft: "15px" }}
                  >
                    <AccountCircleIcon className="mr-4" />
                    {accountInfo.username ?? "ユーザー名"}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="success"
                    style={{ marginLeft: "15px" }}
                    component={Link}
                    to="/login"
                  >
                    <LoginIcon className="mr-1" />
                    ログイン
                  </Button>
                )}
              </ThemeProvider>
            )}
          </Box>
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <div style={{ color: "#3E6EB4" }}>
              <MenuItem onClick={handleNavigateDashboard}>
                ダッシュボード
              </MenuItem>
              {accountInfo.roles.admin && (
                <Fragment>
                  <MenuItem
                    onClick={() => handleNavigateManagement("authority")}
                  >
                    権限管理
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleNavigateManagement("customer")}
                  >
                    顧客アカウント管理
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleNavigateManagement("subcontractor")}
                  >
                    外注先アカウント管理
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleNavigateManagement("employee")}
                  >
                    自社アカウント管理
                  </MenuItem>
                  <MenuItem onClick={() => handleNavigateManagement("system")}>
                    システム設定
                  </MenuItem>
                </Fragment>
              )}
              <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
            </div>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
