import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Box, Button, Card, CardContent, TextField } from "@mui/material";
import axiosConfig from "../../axiosConfig";
import { useNavigate } from "react-router-dom";

interface FormData {
  username: string;
  email: string;
  password: string;
  fullname: string;
  fullname_kana: string;
  phone_number: string;
  confirmPassword: string;
  type: string;
  [key: string]: string | "";
}

interface objectFormData {
  [key: string]: {
    max: number | null;
    min: number | null;
    name: string | null;
    required: boolean | false;
  };
}

const CustomerRegister: React.FC = () => {
  const navigate = useNavigate();
  const initialForm = {
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    fullname: "",
    fullname_kana: "",
    phone_number: "",
    type: "customer",
  };
  const initialValidate = {
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    fullname: "",
    fullname_kana: "",
    phone_number: "",
  };
  const [errors, setErrors] = useState<Partial<FormData>>(initialValidate);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorServer, setErrorServer] = useState<string>("");
  const [formData, setFormData] = useState<FormData>(initialForm);

  const validate = (field: string, value: string) => {
    let error = "";
    const regexUsername: RegExp = /^[a-zA-Z0-9]+$/;
    const regexPassword: RegExp =
      /^[a-zA-Z0-9!"#$%&'()\-^@[\];:,./=~|`{+*}<>?_]+$/;
    const regexEmail: RegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const regexFullname: RegExp =
      /^(?:[\u3041-\u3096\u30A1-\u30FA\u30FC\u4E00-\u9FFF\uFF10-\uFF19\uFF21-\uFF3A\uFF41-\uFF5A\s]|[A-Za-z0-9])+$/;
    const regexFullnameKana = /^[\u30A1-\u30FA\u30FC\s]+$/;
    const regexPhone: RegExp = /^[0-9\\-]+$/;

    const validateKeys: objectFormData = {
      username: {
        max: 20,
        min: 3,
        name: "ログインID",
        required: true,
      },
      password: {
        max: 50,
        min: 8,
        name: "パスワード",
        required: true,
      },
      fullname: {
        max: 50,
        min: 3,
        name: "担当者のお名前",
        required: true,
      },
      fullname_kana: {
        max: 50,
        min: 3,
        name: "担当者のお名前（カナ）",
        required: true,
      },
      phone_number: {
        max: null,
        min: null,
        name: "携帯電話番号",
        required: true,
      },
      confirmPassword: {
        max: 50,
        min: 8,
        name: "確認のため再度パスワードを入力",
        required: true,
      },
      email: {
        max: null,
        min: null,
        name: "メールアドレス",
        required: true,
      },
    };

    if (validateKeys[field].required && value.length === 0) {
      error = "必須項目です。";
    } else if (value !== "") {
      if (
        (field === "confirmPassword" && value !== formData.password) ||
        (field === "password" &&
          value !== formData.confirmPassword &&
          formData.confirmPassword !== "")
      ) {
        return setErrors({
          ...errors,
          confirmPassword: "入力されたパスワードが一致しません。",
        });
      }

      if (formData.confirmPassword !== "")
        if (
          validateKeys[field].max !== null &&
          validateKeys[field].min !== null
        ) {
          if (
            value.length > (validateKeys[field].max ?? 0) ||
            value.length < (validateKeys[field].min ?? 0)
          )
            error = `${validateKeys[field].min}～${validateKeys[field].max}文字以内で入力してください。`;
        }

      if (field === "phone_number") {
        const formatPhone = value.replace(/-/g, "");
        if (formatPhone.length !== 10) error = `10数字で入力してください。`;
      }

      if (
        (field === "username" && !regexUsername.test(value)) ||
        (field === "email" && !regexEmail.test(value)) ||
        (field === "password" && !regexPassword.test(value)) ||
        (field === "confirmPassword" && !regexPassword.test(value)) ||
        (field === "fullname" && !regexFullname.test(value)) ||
        (field === "fullname_kana" && !regexFullnameKana.test(value)) ||
        (field === "phone_number" && !regexPhone.test(value))
      ) {
        error = `${validateKeys[field].name}の値が不正です。`;
      }
    }
    setErrors({ ...errors, [field]: error });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    validate(name, value);
  };

  const validateRequiredAll = () => {
    let isValid = true;
    Object.keys(formData).forEach((key: string) => {
      if (formData[key].length === 0) {
        setErrors((errors) => {
          return { ...errors, [key]: "必須項目です。" };
        });
        isValid = false;
      }
    });
    return isValid;
  };

  //Handle Submit
  const handleSubmit = async () => {
    setErrorServer("");
    const valid = validateRequiredAll();

    if (valid)
      await axiosConfig({
        method: "post",
        url: "/development/api/auth/register",
        data: {
          ...formData,
          phone_number: formData.phone_number.replace(/-/g, ""),
        },
      })
        .then((res) => {
          if (res.status === 201 && res.data != null) {
            setFormData({ ...initialForm });
            setErrors({});
            setErrorServer("");
            setIsSuccess(true);
            setTimeout(() => setIsSuccess(false), 5000);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status) {
            if (err.response.status === 409) {
              setErrorServer(`${err.response.data.detail}`);
            } else
              setErrorServer(
                `エラーが発生しました。${err.response.status}システムの管理者にお問い合わせください。`
              );
          }
        });
  };
  return (
    <div className="bg-gray-100 flex flex-col" style={{ minHeight: "100vh" }}>
      <Header></Header>
      <div className="my-auto flex">
        <Card className="mx-auto" sx={{ width: "350px" }}>
          <CardContent>
            <TextField
              required
              autoComplete="one-time-code"
              margin="dense"
              name="username"
              value={formData.username}
              label="ログインID"
              fullWidth
              variant="outlined"
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.username}
              error={Boolean(errors.username)}
            />
            <TextField
              required
              autoComplete="one-time-code"
              margin="dense"
              name="password"
              value={formData.password}
              label="パスワード "
              type="password"
              fullWidth
              variant="outlined"
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.password}
              error={Boolean(errors.password)}
            />
            <TextField
              required
              autoComplete="one-time-code"
              margin="dense"
              name="confirmPassword"
              value={formData.confirmPassword}
              label="確認のため再度パスワードを入力"
              type="password"
              fullWidth
              variant="outlined"
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.confirmPassword}
              error={Boolean(errors.confirmPassword)}
            />
            <TextField
              required
              margin="dense"
              name="fullname"
              value={formData.fullname}
              label="担当者のお名前"
              fullWidth
              variant="outlined"
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.fullname}
              error={Boolean(errors.fullname)}
            />
            <TextField
              required
              margin="dense"
              name="fullname_kana"
              value={formData.fullname_kana}
              label="担当者のお名前（カナ）"
              fullWidth
              variant="outlined"
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.fullname_kana}
              error={Boolean(errors.fullname_kana)}
            />
            <TextField
              required
              margin="dense"
              name="email"
              value={formData.email}
              label="メールアドレス"
              type="email"
              fullWidth
              variant="outlined"
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.email}
              error={Boolean(errors.email)}
            />
            <TextField
              margin="dense"
              name="phone_number"
              value={formData.phone_number}
              label="携帯電話番号"
              fullWidth
              variant="outlined"
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.phone_number}
              error={Boolean(errors.phone_number)}
            />
            {errorServer && (
              <p style={{ color: "red", marginTop: "10px" }}>{errorServer}</p>
            )}
            {isSuccess && (
              <p style={{ color: "green", marginTop: "10px" }}>
                作成に成功しました。
              </p>
            )}
            <Box sx={{ "& button": { mt: 2 } }}>
              <Button
                className="w-full"
                onClick={handleSubmit}
                variant="contained"
                autoFocus
              >
                登録
              </Button>
              <Button
                className="w-full "
                variant="contained"
                color="success"
                onClick={() => navigate("/login")}
              >
                ログイン画面に戻る
              </Button>
            </Box>
          </CardContent>
        </Card>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default CustomerRegister;
