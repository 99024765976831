import {
  DialogContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Button,
  styled,
  TableCell,
  tableCellClasses,
  Container,
} from "@mui/material";
import { useEffect, useState } from "react";
import { estimationAmountState } from "../features/estimationAmount/estimationAmountSlice";
import { useDispatch } from "react-redux";
import { updateEstimationFromList } from "../features/estimationAmountList/estimationAmountListSlice";
import { selectEstimationAmountList } from "../features/estimationAmountList/estimationAmountListSlice";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { useAppSelector } from "../app/hooks";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { addDays, format, isWeekend } from "date-fns";
import {
  getformatDateFromISOFormat,
  toJSTISOString,
} from "../components/Utils/utils";

const EstimateAndDeliveryPlan: React.FC = () => {
  const [isDisable, setIsDisable] = useState(true);
  const navigate = useNavigate();
  const estimateAmountList: estimationAmountState[] = useAppSelector(
    selectEstimationAmountList
  );
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#000",
      color: "#fff",
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      color: "#5589A7",
    },
  }));
  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#F6F6F6",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const [disabledDates, setDisabledDates] = useState<string[]>([]);

  useEffect(() => {
    let isHasData = false;
    estimateAmountList.forEach((item) => {
      if (item.status === "uploaded") isHasData = true;
    });
    if (!isHasData) {
      navigate("/");
    }
    const fetchDisabledDates = async () => {
      try {
        const response = await axios.get(
          // "https://6o556yrzjc.execute-api.ap-northeast-1.amazonaws.com/stage/non-operating-days"
          `${process.env.REACT_APP_BASE_URL}/non-operating-days`
        );
        setDisabledDates(response.data);
      } catch (error) {
        console.error("Error fetching disabled dates:", error);
      }
    };
    fetchDisabledDates();
  }, []);

  useEffect(() => {
    const checkIsCheckedAll = () => {
      let isCheckedAll = true;
      estimateAmountList.forEach((item) => {
        if (item.deliveryPlan === "") {
          isCheckedAll = false;
        }
      });
      setIsDisable(!isCheckedAll);
    };
    checkIsCheckedAll();
  }, [estimateAmountList, navigate]);

  const dispatch = useDispatch();

  const handleChangeCheckbox = (row: number, type: string) => {
    dispatch(
      updateEstimationFromList({
        index: row,
        deliveryPlan: type,
        deliveryDate: toJSTISOString(deliveryDatePlan(type)),
      })
    );
  };

  const isChecked = (index: number, type: string) => {
    if (estimateAmountList[index]?.deliveryPlan === type) {
      return true;
    }
    return false;
  };

  const handleSelectDeliveryPlan = () => {
    navigate("/input-customer-info");
  };

  const deliveryDatePlan = (type: string) => {
    let workdays = 0;
    if (type === "immediate") workdays = 2;
    if (type === "standard") workdays = 7;
    if (type === "margin") workdays = 14;

    let currentDate = new Date();
    let validDays = 0;
    while (validDays < workdays) {
      currentDate = addDays(currentDate, 1);
      const formattedDate = format(currentDate, "yyyy-MM-dd");
      if (!isWeekend(currentDate) && !disabledDates.includes(formattedDate)) {
        validDays++;
      }
    }
    return currentDate;
  };

  return (
    <>
      <Header pageTitle="納期の選択" />
      <Container
        style={{ marginTop: "2%", marginBottom: "100px", minWidth: "90vw" }}
      >
        <DialogContent sx={{ paddingTop: 0 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">ファイル名</StyledTableCell>
                  <StyledTableCell align="center">
                    2営業日
                    <p style={{ fontWeight: "normal" }}>
                      本日{new Date().getDate()}
                      日中のご発注での発注で
                      {getformatDateFromISOFormat(
                        deliveryDatePlan("immediate").toISOString()
                      )}
                    </p>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    7営業日
                    <p style={{ fontWeight: "normal" }}>
                      本日{new Date().getDate()}
                      日中のご発注での発注で
                      {getformatDateFromISOFormat(
                        deliveryDatePlan("standard").toISOString()
                      )}
                    </p>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    14営業日
                    <p style={{ fontWeight: "normal" }}>
                      本日{new Date().getDate()}
                      日中のご発注での発注で
                      {getformatDateFromISOFormat(
                        deliveryDatePlan("margin").toISOString()
                      )}
                    </p>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {estimateAmountList.map(
                  (row, index) =>
                    row.status === "uploaded" && (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          align="center"
                          component="th"
                          scope="row"
                          size="small"
                        >
                          {row.estimationFileName}
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          <FormControlLabel
                            label={`${row.immediate}円`}
                            control={
                              <Checkbox
                                checked={isChecked(index, "immediate")}
                                value={row.immediate}
                                onChange={() =>
                                  handleChangeCheckbox(index, "immediate")
                                }
                              />
                            }
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          <FormControlLabel
                            label={`${row.standard}円`}
                            control={
                              <Checkbox
                                checked={isChecked(index, "standard")}
                                value={row.standard}
                                onChange={() =>
                                  handleChangeCheckbox(index, "standard")
                                }
                              />
                            }
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          <FormControlLabel
                            label={`${row.margin}円`}
                            control={
                              <Checkbox
                                checked={isChecked(index, "margin")}
                                value={row.margin}
                                onChange={() =>
                                  handleChangeCheckbox(index, "margin")
                                }
                              />
                            }
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ paddingX: "40px", margin: "0 auto 15px auto" }}
            onClick={handleSelectDeliveryPlan}
            disabled={isDisable}
          >
            次へ
          </Button>
        </DialogActions>
      </Container>
      <Footer />
    </>
  );
};
export default EstimateAndDeliveryPlan;
