import React, { useState, ChangeEvent } from "react";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid,
  Box,
  ListSubheader,
  Checkbox,
  styled,
} from "@mui/material";

interface FileUploadRev extends File {
  isChecked: boolean;
}

interface estimationListProps {
  listItems: FileUploadRev[];
  setListItems: any;
}

const FileUploadList: React.FC<estimationListProps> = ({
  listItems,
  setListItems,
}) => {
  // チェックボックスの色を変更するためのスタイルを定義
  const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    // チェック前の色を指定
    "&.MuiCheckbox-root": {
      color: "white",
    },
    // // チェック後の色を指定
    // "&.Mui-checked + .MuiFormControlLabel-label": {
    //   color: theme.palette.primary.main,
    // },
  }));

  const [checkAllState, setCheckAllState] = useState<Boolean>(false);

  const handleCheckAllChange = (event: ChangeEvent<HTMLInputElement>) => {
    // Checkboxの状態を更新
    setCheckAllState(event.target.checked);
    const updateCheckedList: any = [];
    listItems.forEach((item) => {
      item.isChecked = event.target.checked;
      updateCheckedList.push(item);
    });
    setListItems(updateCheckedList);
  };

  const handleChangeCheckBox = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setCheckAllState(false);
    setListItems((prevData: FileUploadRev[]) => {
      return prevData.map((item: FileUploadRev, i: number) => {
        if (i === index) {
          item.isChecked = event.target.checked;
        }
        return item;
      });
    });
  };
  // 見積削除処理
  const deleteEstimation = (itemIndex: number) => {
    const newList = listItems.filter((item, index) => {
      if (index !== itemIndex) return item;
    });
    setListItems(newList);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "baseline",
          m: "24px 0px",
          pl: 2,
          position: "relative",
          pb: 1, // paddingBottom: 16px
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "4px",
            backgroundImage:
              "linear-gradient(to right, #000000 30%, #8E8E8E 30%)",
          },
        }}
      >
        <Typography variant="h5">見積対象ファイル一覧</Typography>
        <Typography sx={{ paddingLeft: "20px" }}>
          見積対象のファイル一覧を表示しています。
        </Typography>
      </Box>
      <List sx={{ padding: "0px" }}>
        <ListSubheader
          sx={{
            backgroundColor: "#000000",
            color: "#ffffff",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={1} style={{ textAlign: "center", padding: "0px" }}>
              <StyledCheckbox
                checked={checkAllState ? true : false}
                onChange={handleCheckAllChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
            <Grid
              item
              xs={10}
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                padding: "0px",
              }}
            >
              <ListItemText
                sx={{
                  margin: "0px",
                }}
                primary="ファイル名"
              />
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                padding: "0px",
              }}
            ></Grid>
          </Grid>
        </ListSubheader>
      </List>
      <List sx={{ padding: "0px", marginTop: "0px" }}>
        {listItems.map((item, index) => (
          <ListItem
            key={index}
            sx={{
              padding: "0px 16px",
              backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white", // 背景色を設定
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container spacing={2} sx={{ marginTop: "0px" }}>
              <Grid
                item
                xs={1}
                style={{
                  textAlign: "center",
                  padding: "0px",
                }}
              >
                <Checkbox
                  onChange={(e) => handleChangeCheckBox(e, index)}
                  checked={item.isChecked}
                />
              </Grid>
              <Grid
                item
                xs={10}
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  padding: "0px",
                }}
              >
                <ListItemText primary={item.name} />
              </Grid>
              <Grid
                item
                xs={1}
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  padding: "0px",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    bgcolor: "grey.500", // グレーの色合いを調整
                    "&:hover": {
                      bgcolor: "grey.700", // ホバー時の色を調整
                    },
                  }}
                  onClick={() => deleteEstimation(index)}
                >
                  削除
                </Button>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default FileUploadList;
