import React from "react";
import {
  Dialog,
  DialogContent,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";
import { Cancel, CheckCircle } from "@mui/icons-material";

// ダイアログコンポーネント
const LoadingDialog: React.FC<{
  isOpen: boolean,
  message: string,
  isSuccess?: boolean,
  messageSuccess?: string,
  messageFailure?: string,
  onClose?: () => void,
}> = ({
  isOpen,
  message,
  isSuccess,
  messageSuccess = "処理が成功しました",
  messageFailure = "処理が失敗しました",
  onClose,
}) => {
    return (
      <>
        {isOpen && (
          <Box
            sx={{
              position: "fixed",
              zIndex: 1300, // Material-UIのバックドロップと同じz-index
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)", // 半透明の背景色
              pointerEvents: "none", // マウスイベントを無効化
            }}
          />
        )}
        <Dialog
          open={isOpen}
          aria-labelledby="loading-dialog"
          onClose={onClose}>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* ローディングメッセージ */}
            <Typography variant="h6">
              {isSuccess === true ? messageSuccess : isSuccess === false ? messageFailure : message}
            </Typography>
            {/* プログレスバー */}
            {
              isSuccess ==true ? (
                <CheckCircle sx={{ color: "green", fontSize: 40 }} />
              ) : isSuccess === false ? (
                <Cancel sx={{ color: "red", fontSize: 40 }} />
              ) : (
                <CircularProgress />
              )

            }
          </DialogContent>
        </Dialog>
      </>
    );
  };

export default LoadingDialog;
