import {
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Card,
  CardHeader,
  Button,
  CardContent,
  Autocomplete,
  TextField,
  Stack,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import GanttChart from "../../components/Dashboard/GanttChart";
import {
  Ticket,
  DataOrder,
  ResDataOrder,
  DrawingStatus,
} from "../../common/constants";
import axiosConfig from "../../axiosConfig";
import { useSearchParams } from "react-router-dom";
import TicketDetail from "../TicketDetail";
import { Close } from "@mui/icons-material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { jaJP } from "@mui/x-data-grid/locales";
import LoadingDialog from "../../components/LoadingDialog/LoadingDialog";
import { getformatDateFromISOFormat } from "../../components/Utils/utils";
import ListAltIcon from "@mui/icons-material/ListAlt";

function Row(props: {
  row: DataOrder;
  setTicketId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setTicketIdSubDialog: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}) {
  const { row, setTicketId, setTicketIdSubDialog } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
        }}
      >
        <TableCell sx={{ width: "30px" }}>
          {row.tickets.length > 0 && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.order.estimation_number}
        </TableCell>
        <TableCell>{row.order.project_name}</TableCell>
        <TableCell>{row.order.pic}</TableCell>
        <TableCell>{row.order.pic_ruby}</TableCell>
        <TableCell>{row.order.phone_number}</TableCell>
        <TableCell>
          <div style={{ display: "flex" }}>
            <Button
              variant="contained"
              size="small"
              disableRipple
              sx={{ marginLeft: "auto" }}
            >
              詳細
            </Button>
          </div>
        </TableCell>
      </TableRow>
      {row.tickets.length > 0 && (
        <TableRow>
          <TableCell sx={{ padding: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {row.tickets
                    .filter((ticketRow) => ticketRow.id !== null)
                    .map((ticketRow: Ticket) => (
                      <TableRow key={ticketRow.id}>
                        <TableCell />
                        <TableCell component="th" scope="row">
                          {ticketRow.file_name}
                        </TableCell>
                        <TableCell>
                          {getformatDateFromISOFormat(ticketRow.delivery_at)}
                        </TableCell>
                        <TableCell>
                          {
                            DrawingStatus[
                            ticketRow.status as keyof typeof DrawingStatus
                            ]
                          }
                        </TableCell>
                        <TableCell>
                          <div style={{ display: "flex" }}>
                            <Button
                              variant="contained"
                              size="small"
                              disableRipple
                              sx={{ marginLeft: "auto" }}
                              onClick={() => {
                                setTicketIdSubDialog(ticketRow.id);
                              }}
                            >
                              チケット個別アサイン
                            </Button>
                            <Button
                              variant="contained"
                              size="small"
                              disableRipple
                              sx={{ marginLeft: "20px" }}
                              onClick={() => {
                                // navigate(`/ticket/${ticketRow.id}`);
                                setTicketId(ticketRow.id);
                              }}
                            >
                              詳細
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

const EmployeeDashboard: React.FC = () => {
  const [showSearchContent, setShowSearchContent] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [data, setData] = useState<ResDataOrder>({
    message: "",
    body: {
      data: [],
      metadata: {
        page: 1,
        per_page: 10,
        page_count: 0,
        total_count: 0,
      },
    },
  });
  const [ticketId, setTicketId] = useState<string>();
  const scrollableRef = useRef<HTMLDivElement | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const searchQuery = useRef({
    pic: "",
    project_name: "",
    file_name: "",
    estimation_number: "",
    is_not_delivered: false,
  });

  const [isCheck, setIsCheck] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const url = "/development/api/dashboard/orders";

    let queryString = "";
    for (const key in searchQuery.current) {
      if (searchQuery.current[key as keyof typeof searchQuery.current]) {
        queryString += queryString !== "" ? "&" : "?";
        queryString += `${key}=${searchQuery.current[key as keyof typeof searchQuery.current]
          }`;
      }
    }

    const response = await axiosConfig.get(`${url}${queryString}`);
    setData(response.data);
    setLoading(false);
  };

  const handleSearch = () => {
    setSearchParams((params) => {
      for (const key in searchQuery.current) {
        if (searchQuery.current[key as keyof typeof searchQuery.current]) {
          params.set(
            key,
            String(searchQuery.current[key as keyof typeof searchQuery.current])
          );
        } else {
          params.delete(key);
        }
      }
      return params;
    });
  };

  useEffect(() => {
    searchQuery.current = {
      pic: searchParams.get("pic") || "",
      project_name: searchParams.get("project_name") || "",
      file_name: searchParams.get("file_name") || "",
      estimation_number: searchParams.get("estimation_number") || "",
      is_not_delivered:
        searchParams.get("is_not_delivered") === "true" ? true : false,
    };

    if (searchParams.get("is_not_delivered") === "true") {
      setIsCheck(true);
    } else {
      setIsCheck(false);
    }

    fetchData();
  }, [searchParams]);

  useEffect(() => {
    if (ticketId && scrollableRef.current) {
      scrollableRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [ticketId]);

  const handleListItemClick = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setTicketId(undefined);
  };

  const [ticketIdSubDialog, setTicketIdSubDialog] = useState<string>();

  const allTicketIds = useMemo(() => {
    return data.body.data
      .map((row) =>
        row.tickets
          .filter(
            (ticket) => ticket.subcontractor_id === null && ticket.id !== null
          )
          .map((ticket) => ticket.id)
      )
      .flat();
  }, [data]);

  return (
    <>
      <div className=" flex flex-col">
        <Header pageTitle="自社ダッシュボード"></Header>
        <Grid container sx={{ height: "90vh" }} className="pb-6">
          <Grid item xs={1.5} className="py-2 bg-gray-100 h-full">
            <List component="nav" sx={{ color: "#3E6EB4" }}>
              <ListItemButton
                selected={selectedIndex === 0}
                onClick={(event) => handleListItemClick(event, 0)}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#3E6EB4",
                    color: "#fff",
                  },
                }}
              >
                <ListAltIcon className="mr-4" />
                <ListItemText primary="案件一覧" />
              </ListItemButton>

              <ListItemButton
                selected={selectedIndex === 1}
                onClick={(event) => handleListItemClick(event, 1)}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#3E6EB4",
                    color: "#fff",
                  },
                }}
              >
                <AnalyticsIcon className="mr-4" />
                <ListItemText primary="ガントチャート" />
              </ListItemButton>
            </List>
          </Grid>
          <Grid
            item
            xs={10.5}
            className="h-full pb-6 pt-4 px-4"
            sx={{ overflowX: "hidden", overflowY: "scroll" }}
            ref={scrollableRef}
          >
            {ticketId ? (
              <TicketDetail
                ticketId={ticketId}
                onBackButtonClick={() => setTicketId(undefined)}
              />
            ) : selectedIndex === 0 ? (
              <>
                <Card
                  className="mx-auto w-full"
                  sx={{ border: 1, borderColor: "grey.300" }}
                >
                  <CardHeader
                    sx={{ bgcolor: "#F5F5F5", paddingY: "5px" }}
                    subheader={
                      <Button
                        variant="text"
                        sx={{ padding: 0, color: "black" }}
                        disableRipple={true}
                        onClick={() => setShowSearchContent(!showSearchContent)}
                      >
                        絞り込み
                      </Button>
                    }
                  />
                  {showSearchContent && (
                    <CardContent sx={{ paddingY: "5px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          <h3>顧客名</h3>
                          <Autocomplete
                            freeSolo
                            className="mt-1"
                            size="small"
                            disableClearable
                            renderInput={(params) => <TextField {...params} />}
                            options={[
                              ...new Set(
                                data.body.data.map(
                                  (row: DataOrder) => row.order.pic
                                )
                              ),
                            ]}
                            value={searchQuery.current.pic}
                            onInputChange={(event, value) => {
                              searchQuery.current.pic = value;
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <h3>案件名</h3>
                          <Autocomplete
                            freeSolo
                            className="mt-1"
                            size="small"
                            disableClearable
                            renderInput={(params) => <TextField {...params} />}
                            options={[
                              ...new Set(
                                data.body.data.map(
                                  (row: DataOrder) => row.order.project_name
                                )
                              ),
                            ]}
                            value={searchQuery.current.project_name}
                            onInputChange={(event, value) => {
                              searchQuery.current.project_name = value;
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <h3>ファイル名</h3>
                          <Autocomplete
                            freeSolo
                            className="mt-1"
                            size="small"
                            disableClearable
                            renderInput={(params) => <TextField {...params} />}
                            options={[
                              ...new Set(
                                data.body.data
                                  .map((row) =>
                                    row.tickets.map(
                                      (ticket) => ticket.file_name
                                    )
                                  )
                                  .flat()
                              ),
                            ]}
                            value={searchQuery.current.file_name}
                            onInputChange={(_, value) =>
                              (searchQuery.current.file_name = value)
                            }
                            getOptionLabel={(option) => option || ""}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <h3>見積番号</h3>
                          <Autocomplete
                            freeSolo
                            className="mt-1"
                            size="small"
                            disableClearable
                            renderInput={(params) => <TextField {...params} />}
                            options={[
                              ...new Set(
                                data.body.data.map(
                                  (row: DataOrder) =>
                                    row.order.estimation_number
                                )
                              ),
                            ]}
                            value={searchQuery.current.estimation_number}
                            onInputChange={(event, value) => {
                              searchQuery.current.estimation_number = value;
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <h3>受注拒否のみ表示</h3>
                            <Checkbox
                              checked={isCheck}
                              onChange={(event) => {
                                searchQuery.current.is_not_delivered =
                                  event.target.checked;
                                setIsCheck(event.target.checked);
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Stack className="mt-1">
                        <div className="ml-auto">
                          <Button
                            variant="contained"
                            sx={{ paddingX: "30px" }}
                            onClick={() => handleSearch()}
                          >
                            検索
                          </Button>
                        </div>
                      </Stack>
                    </CardContent>
                  )}
                </Card>
                <Card
                  className="mx-auto w-full mt-4"
                  sx={{ width: "100%", border: 1, borderColor: "grey.300" }}
                >
                  <CardHeader
                    sx={{ bgcolor: "#F5F5F5", paddingY: "10px" }}
                    subheader={"案件リスト"}
                    action={!loading && data.body.data.length > 0 && <AssignAllTicketsButton ticketIds={allTicketIds} refreshData={fetchData} />}
                  ></CardHeader>
                  <CardContent>
                    <TableContainer component={Paper}>
                      <Table aria-label="collapsible table" size="small">
                        {loading && (
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              paddingY: 10,
                            }}
                          >
                            <CircularProgress
                              sx={{ marginLeft: "auto", marginRight: "auto" }}
                            />
                          </Box>
                        )}
                        {
                          !loading && data.body.data.length === 0 && (
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                paddingY: 10,
                              }}
                            >
                              <p
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                データがありません。
                              </p>
                            </Box>
                          )
                        }
                        {
                          !loading && data.body.data.length > 0 && (
                            <TableBody>
                              {data.body.data.map((row: DataOrder) => (
                                <Row
                                  key={row.order.id}
                                  row={row}
                                  setTicketId={setTicketId}
                                  setTicketIdSubDialog={setTicketIdSubDialog}
                                />
                              ))}
                            </TableBody>)}
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </>
            ) : (
              selectedIndex === 1 && <GanttChart />
            )}
          </Grid>
        </Grid>
        <Footer></Footer>
      </div>
      <SelectSubcontractorDialog
        open={ticketIdSubDialog !== undefined}
        onClose={() => setTicketIdSubDialog(undefined)}
        ticketId={ticketIdSubDialog}
        refreshData={fetchData}
      />
    </>
  );
};

interface SearchInput {
  fullname: string;
  username: string;
  [key: string]: string;
}

function SelectSubcontractorDialog({
  open,
  onClose,
  ticketId,
  refreshData,
}: {
  open: boolean;
  onClose: () => void;
  ticketId?: string;
  refreshData: () => {};
}) {
  const [subcontractorId, setSubcontractorId] = useState<string>();

  const columns: GridColDef[] = [
    {
      field: "select",
      headerName: "",
      flex: 0.1,
      sortable: false,
      renderCell: (params) => (
        <Checkbox
          color="primary"
          checked={subcontractorId === params.row.id}
          onChange={(e) => {
            setSubcontractorId(params.row.id);
          }}
        />
      ),
    },
    {
      field: "id",
      headerName: "ID",
      flex: 0.2,
    },
    {
      field: "username",
      headerName: "ログインID",
      flex: 0.3,
    },
    {
      field: "fullname",
      headerName: "氏名",
      flex: 0.3,
    },

    {
      field: "email",
      headerName: "メールアドレス",
      flex: 0.3,
    },
    {
      field: "phone_number",
      headerName: "電話番号",
      flex: 0.3,
    },
  ];
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState<Partial<SearchInput>>({
    fullname: "",
    username: "",
  });
  const [fullnameList, setFullnameList] = useState<string[]>([]);
  const [usernameList, setUsernameList] = useState<string[]>([]);

  const fetchData = async (search = "") => {
    let url = "/development/api/users/subcontractor";
    if (search !== "") {
      url += `?${search}`;
    }
    await axiosConfig.get(url).then((res) => {
      setFullnameList(res.data.data.map((item: any) => item.fullname));
      setUsernameList(res.data.data.map((item: any) => item.username));
      setData(res.data.data);
    });
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean>();

  const handleChangeSearchInput = (value: string, field: string) => {
    setSearchInput((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSearch = async () => {
    let searchUrl = "";
    const keys = Object.keys(searchInput);
    keys.forEach((key) => {
      if (searchInput[key] !== "") {
        if (searchUrl.length === 0) searchUrl += `${key}=${searchInput[key]}`;
        else searchUrl += `&${key}=${searchInput[key]}`;
      }
    });
    await fetchData(searchUrl);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onCloseDialog = () => {
    setSubcontractorId(undefined);
    if (searchInput.fullname || searchInput.username) {
      setSearchInput({
        fullname: "",
        username: "",
      });
      fetchData();
    }
    onClose();
  };

  const assignSubcontractor = async ({
    subcontractorId,
    ticketId,
  }: {
    subcontractorId: string;
    ticketId: string;
  }) => {
    setIsLoading(true);
    try {
      await axiosConfig.post(`/development/api/dashboard/assign-tickets`, {
        ticket_ids: [ticketId],
        subcontractor_id: subcontractorId,
      });
      setIsSuccess(true);
      refreshData();
    } catch (error) {
      console.error(error);
      setIsSuccess(false);
    }
  };

  return (
    <>
      <LoadingDialog
        isOpen={isLoading}
        message={"処理中"}
        isSuccess={isSuccess}
        onClose={() => {
          setIsLoading(false);
          setTimeout(() => {
            setIsSuccess(undefined);
          }, 1000);
        }}
      />
      <Dialog
        onClose={() => {
          onCloseDialog();
        }}
        aria-labelledby="select-subcontractor-dialog"
        open={open}
        maxWidth={"md"}
        fullWidth={true}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="select-subcontractor-dialog">
          協力会社選択
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            onCloseDialog();
          }}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <Card className="mx-auto" sx={{ border: 1, borderColor: "grey.300" }}>
            <CardHeader sx={{ bgcolor: "#F5F5F5", paddingY: "5px" }} />
            <CardContent sx={{ paddingY: "5px" }}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <h3>氏名</h3>
                  <Autocomplete
                    freeSolo
                    className="mt-1"
                    size="small"
                    disableClearable
                    renderInput={(params) => <TextField {...params} />}
                    options={fullnameList}
                    value={searchInput.fullname}
                    onInputChange={(e, value) =>
                      handleChangeSearchInput(value, "fullname")
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <h3>ログインID</h3>
                  <Autocomplete
                    freeSolo
                    className="mt-1"
                    size="small"
                    disableClearable
                    renderInput={(params) => <TextField {...params} />}
                    options={usernameList}
                    value={searchInput.username}
                    onInputChange={(e, value) =>
                      handleChangeSearchInput(value, "username")
                    }
                  />
                </Grid>
              </Grid>
              <Stack className="mt-2">
                <div className="ml-auto">
                  <Button variant="contained" onClick={handleSearch}>
                    検索
                  </Button>
                </div>
              </Stack>
            </CardContent>
          </Card>
          <Card
            className="mx-auto mt-5 mb-4"
            sx={{ border: 1, borderColor: "grey.300" }}
          >
            <CardHeader sx={{ bgcolor: "#F5F5F5", padding: "12px" }} />
            <CardContent>
              <DataGrid
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                rows={data}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[10, 20, 30]}
                disableColumnMenu
                disableColumnSorting
                disableRowSelectionOnClick
              />
              {data.length === 0 && (
                <Typography variant="subtitle1" align="center" sx={{ mt: 2 }}>
                  データがありません
                </Typography>
              )}
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{ paddingX: "30px" }}
            onClick={() => {
              assignSubcontractor({
                subcontractorId: subcontractorId!,
                ticketId: ticketId!,
              });
              onCloseDialog();
            }}
          >
            確認する
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function AssignAllTicketsButton({ ticketIds, refreshData }: { ticketIds: string[], refreshData: () => {} }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean>();

  const assignAllTickets = async () => {
    const url = "/development/api/dashboard/assign-tickets";
    setIsLoading(true);
    try {
      await axiosConfig.post(url, {
        ticket_ids: ticketIds,
      });
      setIsSuccess(true);
      refreshData();
    } catch (error) {
      console.error(error);
      setIsSuccess(false);
    }
  };

  return (
    <>
      <LoadingDialog
        isOpen={isLoading}
        message={"処理中"}
        isSuccess={isSuccess}
        onClose={() => {
          setIsLoading(false);
          setTimeout(() => {
            setIsSuccess(undefined);
          }, 1000);
        }}
      />
      <Button
        variant="contained"
        size="small"
        disableRipple
        onClick={assignAllTickets}
      >
        すべてのチケットを発注
      </Button>
    </>
  );
}

export default EmployeeDashboard;
