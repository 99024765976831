import axios from "axios";
import Cookies from "js-cookie";

const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: false,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosConfig.interceptors.request.use(
  (config) => {
    const token = Cookies.get("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosConfig.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.data.error === "Expired_Token"
    ) {
      localStorage.clear();
      Cookies.remove("access_token");
      return window.location.replace("/login");
    }
    return Promise.reject(error);
  }
);

export default axiosConfig;
