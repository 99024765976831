import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import axiosConfig from "../../axiosConfig";
import { Button, Card, CardContent, TextField, Link, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setAccount } from "../../features/accountInfo/accountInfoSlice";
import { LoadingButton } from "@mui/lab";
import {
  customerInfoState,
  selectCustomerInfo,
} from "../../features/customerInfo/customerInfoSlice";

const Login: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [usernameError, setUsernameError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const customerInfo: customerInfoState = useAppSelector(selectCustomerInfo);

  useEffect(() => {
    if (Cookies.get("access_token")) {
      navigate("/");
    }
  }, []);

  const rules = () => {
    let isValid = true;
    const regexUsername: RegExp = /^[a-zA-Z0-9]+$/;
    const regexPassword: RegExp =
      /^[a-zA-Z0-9!"#$%&'()\-^@[\];:,./=~|`{+*}<>?_]+$/;

    if (username.length === 0) {
      setUsernameError("必須項目です。");
      isValid = false;
    } else if (!regexUsername.test(username)) {
      setUsernameError("ログインIDの値が不正です。");
      isValid = false;
    }

    if (password === "") {
      setPasswordError("必須項目です。");
      isValid = false;
    } else if (!regexPassword.test(password)) {
      setPasswordError("パスワードの値が不正です。");
      isValid = false;
    }

    return isValid;
  };
  const handleLogin = async () => {
    setError("");
    setUsernameError("");
    setPasswordError("");
    setIsLoading(true);
    if (rules()) {
      await axiosConfig({
        method: "post",
        url: "/development/api/auth/login",
        data: {
          username: username,
          password: password,
        },
      })
        .then((res) => {
          if (res.status === 200 && res.data != null) {
            Cookies.set("access_token", res.data.access_token);
            dispatch(setAccount(res.data));
            if (res.data.type === "employee") navigate("/dashboard/employee");
            if (res.data.type === "subcontractor")
              navigate("/dashboard/subcontractor");
            if (res.data.type === "customer") {
              if (customerInfo.status === "complete")
                navigate("/estimation-display");
              else navigate("/dashboard/customer");
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401)
              setError("ログインIDまたはパスワードが正しくありません。");
            else {
              setError(
                `エラーが発生しました。「${err.response.status}」\nシステムの管理者にお問い合わせください。`
              );
            }
          } else {
            setError(
              "エラーが発生しました。システムの管理者にお問い合わせください。"
            );
          }
        });
    }
    setIsLoading(false);
  };

  return (
    <div className="bg-gray-100 flex flex-col" style={{ minHeight: "100vh" }}>
      <Header hideTop></Header>
      <div className="my-auto flex">
        <Card className="mx-auto" sx={{ width: "350px" }}>
          <CardContent>
            <div className="mb-1 font-bold">ログインID</div>
            <TextField
              error={usernameError.length > 0}
              helperText={usernameError}
              size="small"
              className="w-full"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <div className="mt-4 mb-1 font-bold">パスワード </div>
            <TextField
              error={passwordError.length > 0}
              helperText={passwordError}
              type="password"
              size="small"
              className="w-full"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleLogin();
                }
              }}
            />
            {error && (
              <pre
                style={{
                  color: "red",
                  marginTop: "10px",
                  inlineSize: "100%",
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                }}
              >
                {error}
              </pre>
            )}
            <div className="mb-5 mt-4 text-xs">
              <Link href="/forgot-password" underline="always">
                パスワードを忘れてしまった
              </Link>
            </div>
            <Box sx={{ "& button": { my: 1 } }}>
              <LoadingButton
                className="w-full font-bold"
                variant="contained"
                loading={isLoading}
                onClick={handleLogin}
              >
                ログイン
              </LoadingButton>
              <Button
                className="w-full font-bold "
                variant="contained"
                color="success"
                onClick={() => {
                  navigate("/register");
                }}
              >
                新規登録
              </Button>
            </Box>
          </CardContent>
        </Card>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Login;
