import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

// 初期状態の定義
const initialState: boolean = false;

export const estimationAmountDisplaySlice = createSlice({
  name: "isEstimationAmountDisplay",
  initialState,
  reducers: {
    isEstimationAmountDisplay: (state, action: PayloadAction<boolean>) => {
      // 新しい値を取得
      state = action.payload;
      return state;
    },
  },
});
// 読み出し用
export const selectEstimationAmountDisplay = (state: RootState) =>
  state.estimationAmountDisplay;
// action用
export const { isEstimationAmountDisplay } =
  estimationAmountDisplaySlice.actions;
export default estimationAmountDisplaySlice.reducer;
