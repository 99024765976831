import {
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Card,
  CardHeader,
  Button,
  CardContent,
  Autocomplete,
  TextField,
  Stack,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  Box,
  CircularProgress,
} from "@mui/material";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useEffect, useRef, useState } from "react";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import {
  Ticket,
  DataOrder,
  ResDataOrder,
  DrawingStatus,
} from "../../common/constants";
import GanttChart from "../../components/Dashboard/GanttChart";
import axiosConfig from "../../axiosConfig";
import { useSearchParams } from "react-router-dom";
import { getformatDateFromISOFormat } from "../../components/Utils/utils";
import TicketDetail from "../TicketDetail";

function Row(props: {
  row: DataOrder;
  setTicketId: React.Dispatch<React.SetStateAction<string | undefined>>;
  fetchData: () => {};
}) {
  const { row, setTicketId } = props;
  const [open, setOpen] = React.useState(false);
  const handleUpdateStatus = async (id: any, status: string) => {
    await axiosConfig({
      method: "patch",
      url: `/development/api/dashboard/tickets/${id}`,
      data: {
        drawing_status: status,
      },
    });
    props.fetchData();
  };
  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
        }}
      >
        <TableCell sx={{ width: "30px" }}>
          {row.tickets.length > 0 && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.order.estimation_number}
        </TableCell>
        <TableCell>{row.order.project_name}</TableCell>
        <TableCell>{row.order.pic}</TableCell>
        <TableCell>{row.order.pic_ruby}</TableCell>
        <TableCell>{row.order.phone_number}</TableCell>
        <TableCell>
          <div style={{ display: "flex" }}>
            <Button
              variant="contained"
              size="small"
              disableRipple
              sx={{ marginLeft: "auto" }}
            >
              詳細
            </Button>
          </div>
        </TableCell>
      </TableRow>
      {row.tickets.length > 0 && (
        <TableRow>
          <TableCell colSpan={12} sx={{ padding: 0 }}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {row.tickets.map((ticketRow: Ticket) => (
                    <TableRow key={ticketRow.id}>
                      <TableCell />
                      <TableCell component="th" scope="row">
                        {ticketRow.file_name}
                      </TableCell>
                      <TableCell>
                        {getformatDateFromISOFormat(ticketRow.delivery_at)}
                      </TableCell>
                      <TableCell>
                        {
                          DrawingStatus[
                          ticketRow.status as keyof typeof DrawingStatus
                          ]
                        }
                      </TableCell>
                      <TableCell>
                        <div style={{ display: "flex" }}>
                          <div className="ml-auto">
                            {ticketRow.status === "contract" && (
                              <>
                                <Button
                                  variant="contained"
                                  size="small"
                                  disableElevation
                                  style={{
                                    backgroundColor: "#DAE8FC",
                                    color: "#000",
                                  }}
                                  onClick={() => {
                                    handleUpdateStatus(
                                      ticketRow.id,
                                      "progress"
                                    );
                                  }}
                                >
                                  受注確定
                                </Button>
                                <Button
                                  variant="contained"
                                  size="small"
                                  disableElevation
                                  style={{
                                    backgroundColor: "#F8CECC",
                                    color: "#000",
                                    marginLeft: "20px",
                                  }}
                                  onClick={() => {
                                    handleUpdateStatus(ticketRow.id, "order");
                                  }}
                                >
                                  受注拒否
                                </Button>
                              </>
                            )}
                            {ticketRow.status === "progress" && (
                              <>
                                <Button
                                  variant="contained"
                                  size="small"
                                  disableElevation
                                  style={{
                                    backgroundColor: "#D5E8D4",
                                    color: "#000",
                                  }}
                                  onClick={() => {
                                    handleUpdateStatus(
                                      ticketRow.id,
                                      "inspection"
                                    );
                                  }}
                                >
                                  納品する
                                </Button>
                              </>
                            )}
                            <Button
                              variant="contained"
                              size="small"
                              disableRipple
                              sx={{ marginLeft: "20px" }}
                              onClick={() =>
                                // naviagte(`/ticket/${ticketRow.id}`)
                                setTicketId(ticketRow.id)
                              }
                            >
                              詳細
                            </Button>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

const SubcontractorDashboard: React.FC = () => {
  const [showSearchContent, setShowSearchContent] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [data, setData] = useState<ResDataOrder>({
    message: "",
    body: {
      data: [],
      metadata: {
        page: 1,
        per_page: 10,
        page_count: 0,
        total_count: 0,
      },
    },
  });
  const tabs = ["contract", "progress", "delivery"];
  const [loading, setLoading] = useState(false);

  const [ticketId, setTicketId] = useState<string>();
  const scrollableRef = useRef<HTMLDivElement | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const searchQuery = useRef({
    project_name: "",
    file_name: "",
    estimation_number: "",
    is_not_delivered: false,
    status: "contract",
  });

  const [isCheck, setIsCheck] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const url = "/development/api/dashboard/orders";

    let queryString = "";
    for (const key in searchQuery.current) {
      if (searchQuery.current[key as keyof typeof searchQuery.current]) {
        queryString += queryString !== "" ? "&" : "?";
        queryString += `${key}=${searchQuery.current[key as keyof typeof searchQuery.current]
          }`;
      }
    }

    const response = await axiosConfig.get(`${url}${queryString}`);
    setLoading(false);
    setData(response.data);
  };

  const handleSearch = () => {
    setSearchParams((params) => {
      for (const key in searchQuery.current) {
        if (searchQuery.current[key as keyof typeof searchQuery.current]) {
          params.set(
            key,
            String(searchQuery.current[key as keyof typeof searchQuery.current])
          );
        } else {
          params.delete(key);
        }
      }
      return params;
    });
  };

  useEffect(() => {
    searchQuery.current = {
      project_name: searchParams.get("project_name") || "",
      file_name: searchParams.get("file_name") || "",
      estimation_number: searchParams.get("estimation_number") || "",
      is_not_delivered:
        searchParams.get("is_not_delivered") === "true" ? true : false,
      status: searchParams.get("status") || "contract",
    };

    if (searchParams.get("is_not_delivered") === "true") {
      setIsCheck(true);
    } else {
      setIsCheck(false);
    }

    if (selectedIndex < tabs.length) {
      setSelectedIndex(tabs.indexOf(searchQuery.current.status));
    }

    fetchData();
  }, [searchParams]);

  useEffect(() => {
    if (ticketId && scrollableRef.current) {
      scrollableRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [ticketId]);

  const handleListItemClick = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    searchQuery.current.status = tabs[index];
    handleSearch();
    setSelectedIndex(index);
    setTicketId(undefined);
  };

  return (
    <div className=" flex flex-col">
      <Header pageTitle="協力会社ダッシュボード"></Header>
      <Grid container sx={{ height: "90vh" }} className="pb-6">
        <Grid item xs={1.5} className="py-2 bg-gray-100 h-full">
          <List component="nav" sx={{ color: "#3E6EB4" }}>
            <ListItemButton
              selected={selectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 0)}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#3E6EB4",
                  color: "#fff",
                },
              }}
            >
              <ListAltIcon className="mr-4" />
              <ListItemText primary="製作依頼案件一覧" />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1)}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#3E6EB4",
                  color: "#fff",
                },
              }}
            >
              <ListAltIcon className="mr-4" />
              <ListItemText primary="受注済み案件一覧" />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 2}
              onClick={(event) => handleListItemClick(event, 2)}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#3E6EB4",
                  color: "#fff",
                },
              }}
            >
              <ListAltIcon className="mr-4" />
              <ListItemText primary="やり直し案件一覧" />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 3}
              onClick={(event) => handleListItemClick(event, 3)}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#3E6EB4",
                  color: "#fff",
                },
              }}
            >
              <AnalyticsIcon className="mr-4" />
              <ListItemText primary="ガントチャート" />
            </ListItemButton>
          </List>
        </Grid>
        <Grid
          item
          xs={10.5}
          className="h-full pb-6 pt-4 px-4"
          sx={{ overflowX: "hidden", overflowY: "scroll" }}
          ref={scrollableRef}
        >
          {ticketId ? (
            <TicketDetail
              ticketId={ticketId}
              onBackButtonClick={() => setTicketId(undefined)}
            />
          ) : selectedIndex !== 3 ? (
            <>
              <Card
                className="mx-auto w-full"
                sx={{ border: 1, borderColor: "grey.300" }}
              >
                <CardHeader
                  sx={{ bgcolor: "#F5F5F5", paddingY: "5px" }}
                  subheader={
                    <Button
                      variant="text"
                      sx={{ padding: 0, color: "black" }}
                      disableRipple={true}
                      onClick={() => setShowSearchContent(!showSearchContent)}
                    >
                      絞り込み
                    </Button>
                  }
                />
                {showSearchContent && (
                  <CardContent sx={{ paddingY: "5px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <h3>案件名</h3>
                        <Autocomplete
                          freeSolo
                          className="mt-1"
                          size="small"
                          disableClearable
                          renderInput={(params) => <TextField {...params} />}
                          options={[
                            ...new Set(
                              data.body.data.map(
                                (row) => row.order.project_name
                              )
                            ),
                          ]}
                          value={searchQuery.current.project_name}
                          onInputChange={(event, value) =>
                            (searchQuery.current.project_name = value)
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <h3>ファイル名</h3>
                        <Autocomplete
                          freeSolo
                          className="mt-1"
                          size="small"
                          disableClearable
                          renderInput={(params) => <TextField {...params} />}
                          options={[
                            ...new Set(
                              data.body.data
                                .map((row) =>
                                  row.tickets.map((ticket) => ticket.file_name)
                                )
                                .flat()
                            ),
                          ]}
                          getOptionLabel={(option) => option || ""}
                          value={searchQuery.current.file_name}
                          onInputChange={(event, value) =>
                            (searchQuery.current.file_name = value)
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <h3>見積番号</h3>
                        <Autocomplete
                          freeSolo
                          className="mt-1"
                          size="small"
                          disableClearable
                          renderInput={(params) => <TextField {...params} />}
                          options={[
                            ...new Set(
                              data.body.data.map(
                                (row) => row.order.estimation_number
                              )
                            ),
                          ]}
                          value={searchQuery.current.estimation_number}
                          onInputChange={(event, value) =>
                            (searchQuery.current.estimation_number = value)
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <h3>未納品のみ表示</h3>
                          <Checkbox
                            checked={isCheck}
                            onChange={(event) => {
                              searchQuery.current.is_not_delivered =
                                event.target.checked;
                              setIsCheck(event.target.checked);
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Stack className="mt-1">
                      <div className="ml-auto">
                        <Button
                          variant="contained"
                          sx={{ paddingX: "30px" }}
                          onClick={() => handleSearch()}
                        >
                          検索
                        </Button>
                      </div>
                    </Stack>
                  </CardContent>
                )}
              </Card>
              <Card
                className="mx-auto w-full mt-4"
                sx={{ width: "100%", border: 1, borderColor: "grey.300" }}
              >
                <CardHeader
                  sx={{ bgcolor: "#F5F5F5", paddingY: "10px" }}
                  subheader={"案件リスト"}
                ></CardHeader>
                <CardContent>
                  <TableContainer component={Paper}>
                    <Table aria-label="collapsible table" size="small">
                      {loading && (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            paddingY: 10,
                          }}
                        >
                          <CircularProgress
                            sx={{ marginLeft: "auto", marginRight: "auto" }}
                          />
                        </Box>
                      )}
                      <TableBody>
                        {!loading &&
                          data?.body.data.map((row: DataOrder) => (
                            <Row
                              key={row.order.id}
                              row={row}
                              setTicketId={setTicketId}
                              fetchData={fetchData}
                            />
                          ))}
                      </TableBody>
                      {!loading && data?.body.data.length <= 0 && (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            paddingY: 10,
                          }}
                        >
                          <p
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          >
                            データがありません。
                          </p>
                        </Box>
                      )}
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </>
          ) : (
            selectedIndex === 3 && <GanttChart />
          )}
        </Grid>
      </Grid>
      <Footer></Footer>
    </div>
  );
};

export default SubcontractorDashboard;
