// FileUpload.tsx
import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Paper,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  CircularProgressProps,
} from "@mui/material";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";

import Proviso from "../../components/Proviso/Proviso";
import { getExtension } from "../../components/Utils/utils";

// redux
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  // estimate,
  estimationAmountAsync,
} from "../../features/estimationAmount/estimationAmountSlice";
import { isEstimationAmountDisplay } from "../../features/isEstimationAmountDisplay/isEstimationAmountDisplaySlice";
import {
  fileSelected,
  selectIsFileSelected,
} from "../../features/isFileSelected/isFileSelectedSlice";

import { S3 } from "aws-sdk";
import { addEstimationToList } from "../../features/estimationAmountList/estimationAmountListSlice";
import { useNavigate } from "react-router-dom";
import FileUploadList from "./FileUploadList";

interface FileUploadRev extends File {
  isChecked: boolean;
}

// スタイル付きのダイアログ
const StyledDialog = styled(Dialog)`
  text-align: center;
`;

// S3の設定: 環境変数からリージョンも読み込む
const s3 = new S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION, // 環境変数からリージョンを読み込む
});

const FileUpload: React.FC = () => {
  // redux
  const isFileSelected = useAppSelector(selectIsFileSelected);
  const dispatch = useAppDispatch();
  // fileUpload
  const [selectedFile, setSelectedFile] = useState<File[] | []>([]);
  const [selectedFileRev, setSelectedFileListRev] = useState<FileUploadRev[]>(
    []
  );
  // DialogState
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [progressLoading, setProgressLoading] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const tempData = [...selectedFileRev];
    const newSelectedFile = tempData.filter((item: any) => {
      return item.isChecked === true;
    });

    setSelectedFile(newSelectedFile);
  }, [selectedFileRev]);

  // 見積開始ボタン押下時
  const handleEstimationClick = async () => {
    // ファイルの状態を確認しファイルが設定されていない場合はアラートを出して終了
    if (isFileSelected.selectedFileName === null) {
      alert("ファイルをアップロードしてください");
      return;
    }

    // ローディング画面を表示
    setDialogOpen(true);
    setProgressLoading(0);
    // 表示フラグをfalseに変更
    dispatch(isEstimationAmountDisplay(false));

    // Fetch estimate data
    await fetchEstimateData();
  };

  const fetchEstimateData = async () => {
    let response: any = null;
    let progressLoadingStatus = 0;
    //アップロードファイルの読み込み
    if (selectedFile.length > 0) {
      for (const file of selectedFile) {
        // 現在のタイムスタンプを取得
        const timestamp = new Date().getTime();
        // ファイル名にタイムスタンプを追加してユニークなキーを作成
        const uniqueFileName = `${timestamp}-${file.name}`;

        const params = {
          Bucket: "raku-cad-trace-bucket",
          Key: uniqueFileName,
          Body: file,
        };

        const resetData = () => {
          setDialogOpen(false);
          setProgressLoading(0);
          setSelectedFile([]);
        };

        try {
          // S3アップロードを実行
          await s3.upload(params).promise();
          // alert("ファイルのアップロードに成功しました！");
          // console.log("ファイルアップロードに成功しました。");
          // ファイルアップロード後にアップロード先の画像URLを取得
          const extention = getExtension(file.name);
          // ここでLambdaに問い合わせる処理
          // とりあえずスタブにつながってる感じ
          // これが本番コード
          response = await dispatch(
            estimationAmountAsync({
              fileName: uniqueFileName,
              fileNameOrigin: file.name,
              extension: extention,
            })
          );

          // すぐにレスポンスが来るので来た後に待ち時間をいれる。
          if (estimationAmountAsync.fulfilled.match(response)) {
            dispatch(
              addEstimationToList({
                ...response.payload,
                immediate: response.payload.estimationAmount * 1.3,
                standard: response.payload.estimationAmount,
                margin: response.payload.estimationAmount * 0.9,
              })
            );
            setProgressLoading(
              ((progressLoadingStatus + 1) / selectedFile.length) * 100
            );
            // ダイアログを非表示にして
            if (progressLoadingStatus + 1 === selectedFile.length) {
              resetData();
              navigate("estimate-delivery-plan");
            } else progressLoadingStatus++;
          }

          if (estimationAmountAsync.rejected.match(response)) {
            alert("計算処理中にエラーが発生しました。もう一度お試しください。");
            resetData();
          }
        } catch (err) {
          console.error("Error", err);
          alert("ファイルのアップロードに失敗しました。");
          resetData();
        }
      }
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFileListRev([]);
    const files = event.target.files;
    if (files) {
      if (files.length > 50) {
        alert("一度にアップロードできるファイルは最大50枚の図面です。");
      } else {
        Object.keys(files).forEach((key) => {
          const file = files[Number(key)];
          const extension = getExtension(file.name).toLowerCase();
          if (extension === "png" || extension === "jpg") {
            setSelectedFile((prevData) => [...prevData, file]);
            const formatFile: any = file;
            formatFile.isChecked = false;
            setSelectedFileListRev((prevData) => [...prevData, formatFile]);
            dispatch(fileSelected({ selectedFileName: file.name }));
          } else {
            alert(
              "サポートされていないファイル形式です。PNGまたはJPGファイルを選択してください。"
            );
            setSelectedFile([]);
          }
        });
      }
    } else {
      alert("ファイルをアップロードしてください");
      setSelectedFile([]);
    }
    event.target.value = "";
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const filesArray: File[] = [];
    Object.keys(files).forEach((key) => {
      const file = files[Number(key)];
      filesArray.push(file);
    });
    if (files) {
      setSelectedFile(filesArray);
    }
  };

  const content = `
    アップロードできるデータ容量は1ファイルyyGまで<br/>
    複数ファイルをお見積りする場合は1ファイルずつ見積を実行してください。<br/>
    アップロードできるファイルはjpgまたはpngの画像ファイルのみです。<br/>
    圧縮ファイルをアップロードしても見積もりはできません。<br/>
    パスワード保護されているファイルは受付できません。<br/>
    jpgまたはpngファイル以外のファイルの場合お問い合わせ窓口へご連絡ください。<br/>
    特殊記号などファイル名に使用の場合、正しいお見積りができない場合があります。ファイル名を変更しお試しください。<br/>
    ブラウザやページを閉じるまたは更新動作を行うとアップロードデータ、見積データは削除されます。<br/>
    <br>
    <b>アップロードが失敗する場合</b><br/>
    ・半角英数のみのファイル名にしてからアップロードしてください。<br/>
    ・別のブラウザでお試しください。<br/>
    ・ブラウザのキャッシュを削除してからお試しください。<br/>
    上記を実施してもアップロードに失敗する場合はお問い合わせ窓口へその旨お伝えください。<br/>
    特殊記号などファイル名に使用の場合、正しいお見積りができない場合があります。ファイル名を変更しお試しください。<br/>
  `;

  const CircularProgressWithLabel = (
    props: CircularProgressProps & { value: number }
  ) => {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: "text.secondary" }}
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  };
  return (
    <div>
      <Container>
        <Paper
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          sx={{
            padding: 2,
            textAlign: "center",
            backgroundColor: "#EDEDED",
            border: "2px dashed #aaa",
            cursor: "pointer",
          }}
        >
          <input
            type="file"
            multiple
            id="fileInput"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <label htmlFor="fileInput">
            <Typography variant="h6" component="div">
              ここに
              <Typography
                variant="h5"
                component="span"
                sx={{ fontWeight: "bold" }}
              >
                1ファイルずつ
              </Typography>
              <br />
              画像ファイルをドラッグ＆ドロップ
            </Typography>
            <Typography variant="h6" component="div">
              または　ここをクリックして画像ファイルを選択してください
              <br />
              jpgまたはpngファイルのみサポートしています
            </Typography>
          </label>
          {selectedFile && (
            <Typography
              variant="h5"
              component="div"
              sx={{ marginTop: 2, fontWeight: "bold" }}
            >
              選択中のファイル: {selectedFile.length}
            </Typography>
          )}
        </Paper>
        <Proviso content={content} />
        <Box>
          <FileUploadList
            listItems={selectedFileRev}
            setListItems={setSelectedFileListRev}
          />
        </Box>
        <Box
          className="mt-4"
          sx={{
            display: "flex", // フレックスボックスを有効にする
            flexDirection: "column", // 縦方向にフレックスアイテムを配置
            alignItems: "center", // 水平方向にセンタリング
            textAlign: "center", // テキストをセンタリング
          }}
        >
          <Box component="div">
            <Typography variant="h5">
              選択中のファイル: {selectedFile.length}
            </Typography>
          </Box>
          <Box
            component="div"
            sx={{
              backgroundColor: "#9e9e9e",
              width: "100px",
              height: "43.3px",
              clipPath: "polygon(50% 100%, 0 0, 100% 0)",
              margin: "0 auto", // 自動的に左右のマージンを設定してセンタリング
            }}
          ></Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleEstimationClick}
            disabled={selectedFile.length === 0}
            sx={{ fontSize: "18px", marginTop: 2 }}
          >
            見積開始
          </Button>
        </Box>
        {/* ポップアップスクリーン */}
        <StyledDialog open={isDialogOpen}>
          <DialogContent>
            {/* テキスト */}
            <Typography variant="h6">AI見積中</Typography>

            {/* プログレスバー */}
            <CircularProgressWithLabel value={progressLoading} />
          </DialogContent>
        </StyledDialog>
      </Container>
    </div>
  );
};

export default FileUpload;
