import React, { Fragment, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Box,
  Typography,
  CardActions,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import axios from "axios";
import axiosConfig from "../../axiosConfig";
import DialogSuccessBackLogin from "../../components/Auth/DialogSuccessBackLogin";
import { validate } from "uuid";

interface ResetPasswordType {
  password: string;
  confirmPassword: string;
  [key: string]: string;
}

const ResetPassword: React.FC = () => {
  const [isValidLink, setIsValidLink] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const initalResetPassword = {
    password: "",
    confirmPassword: "",
  };
  const [formData, setFormData] =
    useState<ResetPasswordType>(initalResetPassword);
  const [errorField, setErrorField] =
    useState<ResetPasswordType>(initalResetPassword);
  const [errorServer, setErrorServer] = useState<string>("");
  const navigate = useNavigate();
  const regexPassword: RegExp =
    /^[a-zA-Z0-9!"#$%&'()\-^@[\];:,./=~|`{+*}<>?_]{8,50}$/;
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    axiosConfig({
      method: "get",
      url: "/development/api/auth/status-reset-token",
      params: {
        token: token,
      },
    })
      .then((res) => {
        if (res.data.status === 200) {
          setIsValidLink(true);
        }
      })
      .catch((error) => {
        if (
          error.response.status &&
          error.response.data.error === "Expired_Reset_Link"
        ) {
          setIsValidLink(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const validateField = (value: string, key: string) => {
    const nameField: ResetPasswordType = {
      password: "パスワード",
      confirmPassword: "確認のため再度パスワードを入力",
    };
    if (value === "") {
      setErrorField((prevError) => {
        return { ...prevError, [key]: "必須項目です。" };
      });
      return false;
    } else if (
      (key === "password" && !regexPassword.test(value)) ||
      (key === "confirmPassword" && !regexPassword.test(value))
    ) {
      setErrorField((prevError) => {
        return {
          ...prevError,
          [key]:
            value.length < 8 || value.length > 50
              ? `8～50文字以内で入力してください。`
              : `${nameField[key]}の値が不正です。`,
        };
      });
      return false;
    } else if (key === "confirmPassword" && formData.password !== value) {
      setErrorField((prevError) => {
        return {
          ...prevError,
          confirmPassword: `入力されたパスワードが一致しません。`,
        };
      });
      return false;
    }
    return true;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));

    let isValidate = validateField(e.target.value, e.target.name);

    if (isValidate) {
      setErrorField((prevError) => {
        return { ...prevError, [e.target.name]: "" };
      });
    }
  };

  const handleClick = async () => {
    setErrorServer("");
    const passwordValid = validateField(formData.password, "password");
    const confirmPasswordValid = validateField(
      formData.confirmPassword,
      "confirmPassword"
    );
    if (passwordValid && confirmPasswordValid) {
      await axios({
        method: "patch",
        url: `${process.env.REACT_APP_BASE_URL}/development/api/auth/reset-password`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          ...formData,
        },
      })
        .then((res) => {
          if (res.status === 200 && res.data != null) {
            setIsSuccess(true);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status) {
            setErrorServer(
              `エラーが発生しました。${err.response.status}\nシステムの管理者にお問い合わせください。`
            );
          } else {
            setErrorServer(
              "エラーが発生しました。\nシステムの管理者にお問い合わせください。"
            );
          }
        });
    }
  };

  return (
    <div className="bg-gray-100 flex flex-col" style={{ minHeight: "100vh" }}>
      <Header hideTop></Header>
      <div className="my-auto">
        <div className="flex">
          {isLoading ? (
            <CircularProgress
              className="mx-auto"
              variant="indeterminate"
              disableShrink
              size={40}
              thickness={4}
            />
          ) : (
            <Card className="mx-auto" sx={{ width: "350px" }}>
              {isValidLink ? (
                <CardContent>
                  <div className="mb-1 font-bold">パスワード</div>
                  <TextField
                    name="password"
                    error={errorField.password.length > 0}
                    helperText={errorField.password}
                    size="small"
                    className="w-full"
                    type="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <div className="mb-1 mt-2 font-bold">
                    確認のため再度パスワードを入力
                  </div>
                  <TextField
                    name="confirmPassword"
                    error={errorField.confirmPassword.length > 0}
                    helperText={errorField.confirmPassword}
                    size="small"
                    type="password"
                    className="w-full"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                  />
                  {errorServer && (
                    <pre
                      style={{
                        color: "red",
                        marginTop: "10px",
                        inlineSize: "100%",
                        wordWrap: "break-word",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {errorServer}
                    </pre>
                  )}
                  <Box sx={{ "& button": { mt: 2 } }}>
                    <Button
                      className="w-full font-bold"
                      variant="contained"
                      onClick={handleClick}
                    >
                      OK
                    </Button>
                    <Button
                      className="w-full font-bold "
                      variant="contained"
                      color="success"
                      onClick={() => navigate("/login")}
                    >
                      ログイン画面に戻る
                    </Button>
                  </Box>
                </CardContent>
              ) : (
                <Fragment>
                  <CardContent>
                    <Typography variant="body2" color="error">
                      再設定可能期間を過ぎたためリンクは無効になっています。パスワードリセットを再リクエストする場合は以下のボタンから再リクエストしてください。
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      style={{ marginLeft: "auto", marginRight: "auto" }}
                      onClick={() => navigate("/forgot-password")}
                    >
                      <KeyboardReturnIcon className="mr-2" />
                      OK
                    </Button>
                  </CardActions>
                </Fragment>
              )}
            </Card>
          )}
        </div>
      </div>
      <DialogSuccessBackLogin
        isSuccess={isSuccess}
        content="パスワードのリセットが成功しました。"
      />
      <Footer></Footer>
    </div>
  );
};

export default ResetPassword;
