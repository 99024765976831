import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import axiosConfig from "../../axiosConfig";
import { Button, Card, CardContent, TextField, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DialogSuccessBackLogin from "../../components/Auth/DialogSuccessBackLogin";
import { LoadingButton } from "@mui/lab";
const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [errorField, setErrorField] = useState<string>("");
  const [errorServer, setErrorServer] = useState<string>("");
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const rules = () => {
    setErrorField("");
    setErrorServer("");
    let isValid = true;
    const regexEmail: RegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (email === "") {
      setErrorField("必須項目です。");
      isValid = false;
    } else if (!regexEmail.test(email)) {
      setErrorField("メールアドレスの値が不正です。");
      isValid = false;
    }

    return isValid;
  };
  const handleClick = async () => {
    setErrorField("");
    setErrorServer("");
    if (rules()) {
      setIsLoading(true);
      await axiosConfig({
        method: "post",
        url: "/development/api/auth/send-mail-forgot-password",
        data: {
          email: email,
        },
      })
        .then((res) => {
          if (res.status === 200 && res.data != null) {
            setIsSuccess(true);
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 404) {
              setErrorServer(
                "入力されたメールアドレスは存在しません。\nもう一度入力してください。"
              );
            } else if (err.response.status !== 404) {
              setErrorServer(
                `エラーが発生しました。${err.response.status}\nシステムの管理者にお問い合わせください。`
              );
            } else {
              setErrorServer(
                "エラーが発生しました。\nシステムの管理者にお問い合わせください。"
              );
            }
          }
        });
    }
    setIsLoading(false);
  };

  return (
    <div className="bg-gray-100 flex flex-col" style={{ minHeight: "100vh" }}>
      <Header hideTop></Header>
      <div className="my-auto">
        <div className="flex">
          <Card className="mx-auto" sx={{ width: "350px" }}>
            <CardContent>
              <div className="mb-1 font-bold">メールアドレス</div>
              <TextField
                error={errorField.length > 0}
                helperText={errorField}
                size="small"
                className="w-full"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => rules()}
              />
              {errorServer && (
                <pre
                  style={{
                    color: "red",
                    marginTop: "10px",
                    inlineSize: "100%",
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {errorServer}
                </pre>
              )}
              <Box sx={{ "& button": { mt: 2 } }}>
                <LoadingButton
                  className="w-full font-bold"
                  variant="contained"
                  loading={isLoading}
                  onClick={handleClick}
                >
                  メールを送信
                </LoadingButton>
                <Button
                  className="w-full font-bold "
                  variant="contained"
                  color="success"
                  onClick={() => navigate("/login")}
                >
                  ログイン画面に戻る
                </Button>
              </Box>
            </CardContent>
          </Card>
          <DialogSuccessBackLogin
            isSuccess={isSuccess}
            content="ご入力いただいたメールアドレスにパスワード再設定案内メールをお送りしました。メールをご確認ください。"
          />
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default ForgotPassword;
