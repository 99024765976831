import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface ErrorProps {
  statusCode?: number;
  message?: string;
}

export default function ErrorComponent({ statusCode, message }: ErrorProps) {
  const naviagte = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
        textAlign: "center",
      }}
    >
      <Typography variant="h1" color="error">
        {statusCode || "Error"}
      </Typography>
      <Typography variant="h6" color="textSecondary">
        {message || "Something went wrong"}
      </Typography>
      <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
        {window.history.length > 1 && (
          <Button variant="outlined" color="primary" onClick={() => naviagte(-1)}>
            戻る
          </Button>
        )}
        <Button variant="contained" color="primary" onClick={() => naviagte("/")}>
          ホームへ
        </Button>
      </Box>
    </Box>
  );
}