// 必要なモジュールをインポート
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Button,
  TextField,
  Container,
  ThemeProvider,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

// ローディング
import LoadingDialog from "../components/LoadingDialog/LoadingDialog";

// カレンダー用フィールド

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Proviso from "../components/Proviso/Proviso";
import { theme } from "../components/Color/color";
// redux
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { changeDisplay } from "../features/managementProcess/managementProcessSlice";
import { selectCustomerInfo } from "../features/customerInfo/customerInfoSlice";
import { selectEstimationAmountList } from "../features/estimationAmountList/estimationAmountListSlice";
import { estimationAmountState } from "../features/estimationAmount/estimationAmountSlice";

// 日付用のアダプタ
// class DateAdapter extends AdapterDateFns {
//   // 参考サイトの実装例よりも、端折っているが、日曜始まりが固定なら以下で十分。
//   getWeekdays = (): string[] => ["日", "月", "火", "水", "木", "金", "土"];
// }

// コンポーネントを定義
const InputInfoConfirmation: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(changeDisplay("InputInfoConfirmation"));
  }, []);

  // reduxから取得したシリアライズされた日付データをデシリアライズする関数
  // const deserializeCustomerInfoState = (
  //   customerInfoState: CustomerInfoState
  // ): FormValues => {
  //   return {
  //     ...customerInfoState,
  //     deliveryDate: customerInfoState.deliveryDate
  //       ? new Date(customerInfoState.deliveryDate)
  //       : null,
  //   };
  // };
  // redux
  // const managementProcess = useAppSelector(selectManagementProcess);
  // const estimationAmountList = useAppSelector(selectEstimationAmountList);
  const customerInfo: any = useAppSelector(selectCustomerInfo);
  // フォーム表示用に調整する関数
  // const customerInfoForDisplay = (customerInfo: FormValues) => {
  //   return {
  //     ...customerInfo,
  //     deliveryDate: customerInfo.deliveryDate
  //       ? format(new Date(customerInfo.deliveryDate), "yyyy年MM月dd日")
  //       : "",
  //   };
  // };

  // チェックボックス用
  // チェックボックスの状態を管理するState
  const [checked, setChecked] = useState(false);
  // チェックボックスの変更ハンドラー
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  // ボタン押下時のダウンロードハンドラ
  const handleMoveEstimationPage = () => {
    // // チェックボックスが有効な見積もりだけをフィルタリング
    // const checkedEstimations = estimationAmountList.filter(
    //   (estimation) => estimation.checkBox
    // );
    // // チェックされた見積もりがない場合の処理
    // // 通常ボタン制御によって1つは選択されるのでこのコードは実行されない
    // if (checkedEstimations.length === 0) {
    //   console.log("No estimations selected for download.");
    // }
    // // チェックされた見積もりを処理（ここではコンソールに表示する例）
    // // 見積もりのUUIDを作成
    // const estimationId = generateUUID();
    // // console.log(estimationId);
    // const updatedEstimations = checkedEstimations.map((estimation) => ({
    //   ...estimation, // 既存のプロパティを保持
    //   estimationId: estimationId, // 全てのオブジェクトに同じ estimationId を設定
    //   estimationItemId: generateUUID(), // 新しい UUID を生成して設定
    //   // ここからフォームから取得した色々を設定する
    //   email: customerInfo.email,
    //   pic: customerInfo.pic,
    //   picRuby: customerInfo.picRuby,
    //   companyName: customerInfo.companyName,
    //   companyNameRuby: customerInfo.companyNameRuby,
    //   postalCode: customerInfo.postalCode,
    //   address: customerInfo.address,
    //   phoneNumber: customerInfo.phoneNumber,
    //   deliveryDate: customerInfo.deliveryDate,
    //   projectName: customerInfo.projectName,
    //   remarks: customerInfo.remarks,
    // }));
    // console.log(updatedEstimations)

    // // jsonコードに変換
    // const data_json = convertToJson(updatedEstimations);
    // console.log(data_json);

    // 見積もり表示ページへ遷移
    navigate("/estimation-display");
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const estimateAmountList: estimationAmountState[] = useAppSelector(
    selectEstimationAmountList
  );

  useEffect(() => {
    let isHasData = false;
    estimateAmountList.forEach((item) => {
      if (item.status === "uploaded") isHasData = true;
    });
    if (!isHasData) {
      navigate("/");
    }
    const fetchData = async () => {
      try {
        //なんか処理する
      } catch (error) {
        console.error("Error fetching disabled dates:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  /**
   * checkedEstimations 配列を JSON 形式に変換する関数
   * @param checkedEstimations estimationAmountState 型の配列
   * @returns JSON 文字列
   */
  // const convertToJson = (checkedEstimations: estimationAmountState[]): string =>
  //   JSON.stringify(checkedEstimations);

  // 送信ハンドラー
  const onSubmit = async (values: any) => {
    try {
      // ここにサーバーにデータを送信するためのaxiosリクエストを追加
      // 例：await axios.post('/api/submit', values);
      // console.log("データが正常に送信されました", values);
    } catch (error) {
      console.error("データの送信中にエラーが発生しました", error);
    }
  };

  // フォームのスタイル定義
  const formStyle = {
    margin: "20px 10px 5px 0px",
  };

  // ページナビゲーション
  const navigate = useNavigate();

  // フォームスタイルの定義
  const StyledTextField = styled(TextField)({
    "& .MuiInputBase-input.Mui-disabled": {
      color: "rgba(0, 255, 0, 0.87) !important", // 文字色を変更
      "-webkit-text-fill-color": "rgba(0, 0, 0, 0.87) !important", // -webkit-text-fill-colorの優先度を上げる
    },
  });

  const content = `
    ご入力いただいた個人情報は、「プライバシーポリシー」に基づいて適切に管理します。<br/>
    また、ご入力いただいたメールアドレス宛に、広告を含むご案内のメールをお送りする場合があります。<br/>
    SSL暗号化通信によってこのページはセキュリティで保護されています。<br/>
    上記情報の取り扱いについて同意される場合は、入力内容をご確認のうえ「次へ」ボタンをクリックしてください。<br/>
    ＊自動返信メールが届かなかった場合は下記アドレスへご連絡ください。<br/>
    mail:rakucad@cad-trace.jp<br/>
  `;

  return (
    <>
      <LoadingDialog isOpen={isLoading} message={"読込中"} />
      <Header pageTitle="お客様情報入力" />
      <Container style={{ marginTop: "16px", marginBottom: "100px" }}>
        <Formik initialValues={customerInfo} onSubmit={onSubmit}>
          {(values) => (
            <Form>
              <Field
                name="email"
                as={StyledTextField}
                label="Email"
                fullWidth
                style={formStyle}
                disabled={true}
              />
              <Field
                name="pic"
                as={StyledTextField}
                label="担当者のお名前"
                fullWidth
                style={formStyle}
                disabled={true}
              />
              <Field
                name="picRuby"
                as={StyledTextField}
                label="担当者のお名前（カナ）"
                fullWidth
                style={formStyle}
                disabled={true}
              />
              <Field
                name="companyName"
                as={StyledTextField}
                label="会社名"
                fullWidth
                style={formStyle}
                disabled={true}
              />
              <Field
                name="companyNameRuby"
                as={StyledTextField}
                label="会社名（カナ）"
                fullWidth
                style={formStyle}
                disabled={true}
              />
              <Field
                name="postalCode"
                as={StyledTextField}
                label="郵便番号"
                fullWidth
                style={formStyle}
                disabled={true}
              />
              <Field
                name="address"
                as={StyledTextField}
                label="住所：県市町村区"
                fullWidth
                style={formStyle}
                disabled={true}
              />
              <Field
                name="address2"
                as={StyledTextField}
                label="住所：マンション名等詳細"
                fullWidth
                style={formStyle}
                disabled={true}
              />
              <Field
                name="phoneNumber"
                as={StyledTextField}
                label="電話番号"
                fullWidth
                style={formStyle}
                disabled={true}
              />
              <Field
                name="projectName"
                as={StyledTextField}
                label="案件名"
                fullWidth
                style={formStyle}
                disabled={true}
              />
              <Field
                name="remarks"
                as={StyledTextField}
                label="備考"
                fullWidth
                style={formStyle}
                disabled={true}
              />
              {/* エラーメッセージ */}
              <ErrorMessage name="email" component="div" />
            </Form>
          )}
        </Formik>
        <Proviso content={content} />
        {/* チェックボックスとテキスト */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "10px 0px",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox checked={checked} onChange={handleCheckboxChange} />
            }
            label="上記の内容を理解しました"
          />
        </Box>
        <Box
          sx={{
            margin: "10px 200px",
          }}
        >
          <Stack direction="row" spacing={2}>
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={!checked} // チェックが入っていない場合は非アクティブ
                onClick={() => {
                  // ダウンロード処理を行う関数
                  handleMoveEstimationPage();
                }}
              >
                次へ
              </Button>
              {/* <Button
                variant="contained"
                color="secondary"
                fullWidth
                disabled={!checked} // チェックが入っていない場合は非アクティブ
                onClick={() => {
                  // 発注処理を行う関数
                }}
              >
                発注
              </Button> */}
              <Button
                variant="outlined"
                fullWidth
                onClick={() => {
                  navigate("/input-customer-info");
                }}
              >
                キャンセル
              </Button>
            </ThemeProvider>
          </Stack>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default InputInfoConfirmation;
